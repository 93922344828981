import PlaceIcon from '@mui/icons-material/Place';
import Button from '@mui/material/Button';
import { JobAdDescription, jobData, one_day } from '.';
import { recoilViewport } from 'components/AnalyticsPanel/VisualTab/atoms';
import { recoilSearchText, recoilSelectedSearchTab } from '../atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import CompareFeature from './CompareFeature';
import GPTFeatures from './GPTFeatures';

function JobAd({ post, preload=false}: { post: jobData, preload?: boolean}) {
  
  const [mapviewport, setMapViewport] = useRecoilState(recoilViewport);
  const [selectedTab, setSelectedTab] = useRecoilState(recoilSelectedSearchTab);
  
  const howLongAgo = //how many days ago
    post.JobDate === null
      ? null
      : Math.round(
          new Date().getTime() / one_day -
            new Date(post.JobDate).getTime() / one_day
        );
  // const timePeriod = useRecoilValue(recoilTimePeriod);


  
  return (
    <div className="justify-between flex flex-row py-5 gap-5 rounded-lg bg-custom-white  border-brand custom-black hover:bg-neutral-color-20 hover:border hover:shadow-md shadow-brand" >
        <div className="flex flex-col gap-5 items-start grow pl-5 max-w-5xl">
        <div className="flex flex-row gap-5 pt-5 items-center">
        <h3 className="text-xl font-bold">{post.CleanJobTitle || '--'}</h3>
        <div className="flex flex-row gap-1 items-center text-neutral-20">
          <span>Percent matched:</span>
          <div
            className={`text-xl font-bold 
            ${
              post.Score > 0.65
                ? 'text-success'
                : post.Score > 0.5
                ? 'text-okayish'
                : 'text-mediocre'
            }`}
          >
            {Math.round(post.Score * 100)}%
          </div>
        </div>
        {post.Latitude != null && post.Longitude != null ? (
          <Button
            sx={{
              width: 32,
              height: 32,
              '& .MuiButton-startIcon': { margin: 0 },
            }}
            variant="outlined"
            startIcon={<PlaceIcon />}
            onClick={() => {
              setMapViewport({
                latitude: parseInt(post.Latitude || '53.52'),
                longitude: parseInt(post.Longitude || '-1.13'),
                zoom: 10.5,
              });
              setSelectedTab(1);
            }}
          ></Button>
        ) : (
          <></>
        )}
      </div>
      <div className="text-neutral-20">
        {howLongAgo === null ? <></> : <span>{howLongAgo} days ago</span>} -{' '}
        {post.CanonCity}/ {post.LocalAuthorithyDistrict}/ {post.CanonCounty}/{' '}
        {post.Region}/ {post.Nation}
      </div>

      <JobAdDescription fullText={post.JobText || ''} />
        </div>
      <div className='top-5 flex flex-col gap-5 items-end' >

        <CompareFeature post={{Sentences:post.Sentences, SentenceScores:post.SentenceScores}}/>
        <GPTFeatures title={post.CleanJobTitle||''} description={post.JobText||''} preload={preload}/>
      </div>
      
    </div>
  );
}

export default JobAd;

