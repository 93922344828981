import { useGetUserQuery } from 'generated/graphql';
import styles from '../styles/components/ProtectedRoute.module.css';
import { useAuthenticationStatus, useUserId } from '@nhost/react';
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const location = useLocation();
  const id = useUserId();
  const { loading, error, data } = useGetUserQuery({ variables: { id } });
  const user = data?.user;

  if (isLoading) {
    return (
      <div className={styles.container}>
        <ClipLoader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="sign-in" state={{ from: location }} replace />;
  }

  if (isAuthenticated && user?.activated === false)
    return (
      <div className={styles.container}>
        <div className={styles.card}>
          <p className={styles['verification-text']}>
            Your profile validation is pending. To gain access to the platform,
            please send an email to registration@oxdatatech.com
          </p>
        </div>
      </div>
    );
  if (isAuthenticated && user?.activated) return <>{children}</>;

  return (
    <div className={styles.container}>
      <ClipLoader />
    </div>
  );
  // }
};

export default ProtectedRoute;
