import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetTermsQuery } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilSkillClustersTerms } from './atoms';

function SkillClustersFilterPanel() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [terms, setTerms] = useRecoilState(recoilSkillClustersTerms);

  //SkillTerms is some skillterms containing the search term ordered by similarity, this will have duplicates duplicates
  const autosuggestedTerms = useGetTermsQuery({
    variables: {
      like: '%' + searchTerm + '%',
    },
    // order_by: { Similarity: Order_By.Desc },
  });

  return (
    <div className="bg-neutral-color-20">
      <div className="flex flex-row max-w-7xl">
        {/* Term selection */}
        <div className="p-5 flex flex-col gap-2 w-1/2 min-w-xl max-w-3xl">
          {/* <h2>Terms</h2> */}
          <Autocomplete
            // suggestions return from query
            multiple
            limitTags={15}
            openOnFocus
            autoComplete
            id="skillterms-clustering"
            options={
              autosuggestedTerms.data?.clusters.map((x) => x.term as string) ||
              []
            }
            loading={autosuggestedTerms.loading}
            // getOptionLabel={(option) => option.label || ''}
            value={terms}
            onChange={(e, v) => setTerms(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Terms"
                value={searchTerm} //search term value
                //update search term state on field change
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

function eliminateDuplicates(array: string[], selectedTerms: string[] = []) {
  return (
    array.filter(
      (v, i, a) => a.indexOf(v) === i && selectedTerms.indexOf(v) === -1
    ) || [] //only keep value if it is the first occurence, and it's not already selected
  );
}

export default SkillClustersFilterPanel;
