export const gradientMonoColorScheme = [
  // '#000',
  '#dedff5',
  '#b6b8ed',
  '#7591e4',
  '#3356c1',
  '#0038b1',
]; //from light blue to dark blue
export const OneColorScheme = ['#3356c1'];
export const gradientBiColorScheme = {
  negative: ['#fadbb7', '#FFC39B', '#FF9B7C', '#ff785a', '#e65f40'],
  positive: ['#9EDFE0', '#8cded2', '#47C6AF', '#13a782', '#087d5f'],
}; //reds and greens
export const MulticolorScheme = [
  '#009BFA',
  '#1CC198',
  '#8292FF',
  '#C65AB7',
  '#FF876D',
  '#FFC158',
  '#F0C987',
  '#A5D8D9',
  '#FFB2D9',
  '#9BA8B7',
  '#F16B6F',
  '#EE964B',
  '#C5E1A5',
  '#B3B2C0',
  '#E5A5D7',
  '#FFD8BE',
  '#A2D6F9',
  '#B0C1D1',
  '#F29D9D',
  '#8E8D9B',
];
