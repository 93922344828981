import 'mapbox-gl/dist/mapbox-gl.css';
import { LngLatBoundsLike, Map } from 'mapbox-gl';
import MapGL, { Image, Popup, Source } from '@urbica/react-map-gl';
import { useLocation } from 'react-router-dom';
import GeoJSON from 'geojson';
import {
  AttributionControl,
  MapContext,
  NavigationControl,
} from '@urbica/react-map-gl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { recoilViewport } from '../../../atoms';
import Layers from './Layers';
import { recoilCursor } from './atoms';
import { Site } from 'components/AnalyticsPanel/SiteProfilesTab';
// import { recoilSelectedEmployers } from 'components/AnalyticsPanel/SiteProfilesTab/atoms';
import { useEffect, useState } from 'react';
import { recoilPostsToDisplayOnMap } from 'components/SearchPanel/atoms';
import MapBrandContainedIcon from '../../../../../../assets/icons/map_brand_contained.png';
import { useFilterValues } from 'components/AnalyticsPanel/VisualTab/VisualFiltersPanel';
import { useGetAllAreasByTypeQuery } from 'generated/graphql';
import { AllGeoJSON, Polygon, area, centroid, union } from '@turf/turf';
import { useAreaCompData } from '../..';
import { setTimeout } from 'timers-promises';

type T = GeoJSON.FeatureCollection<GeoJSON.Point>;
function MapComponent({
  siteProfileMode = false,
  searchMode = false,
  minZoom = 4,
  sites = [],
  mapRef = null,
  setMapRef = () => {},
}: // sites = {}
{
  searchMode?: boolean;
  siteProfileMode?: boolean;
  sites?: Site[];
  minZoom?: number;
  mapRef?: MapGL | null;
  setMapRef?: Function;
}) {
  const [viewport, setViewport] = useRecoilState(recoilViewport);
  const path = useLocation().pathname;
  const searchData = useRecoilValue(recoilPostsToDisplayOnMap);
  const cursor = useRecoilValue(recoilCursor);
  const [valuesToDisplay, setValuesToDisplay] = useState<T>({
    type: 'FeatureCollection',
    features: [],
  });
  const [loaded, setLoaded] = useState(false);
  const map_gl = (
    // <></>;
    <MapGL
      cursorStyle={cursor}
      mapStyle="mapbox://styles/cazembe/clhp26u0u01ty01pgdxlu3kpj"
      accessToken="pk.eyJ1IjoiY2F6ZW1iZSIsImEiOiJjbGV6aXBvZ2gzNHUxNDRydndtOW0yaXQwIn0.N0GMy0XZ4kqZz7mUbZcG5w"
      onViewportChange={setViewport}
      className="h-full w-full relative text-base"
      onLoad={() => setLoaded(true)}
      // cursorStyle={cursor}
      attributionControl={false}
      viewportChangeMethod="easeTo"
      hash
      minZoom={minZoom}
      // maxZoom={11}
      maxBounds={
        [
          [-10, 40],
          [10, 65],
        ] as LngLatBoundsLike
      }
      bounds={
        [
          [-7, 48],
          [4, 62],
        ] as LngLatBoundsLike
      }
      {...{ path }}
      // {...viewport}
      latitude={viewport.latitude}
      longitude={viewport.longitude}
      zoom={viewport.zoom}
      ref={(map) => setMapRef(map)}
      preserveDrawingBuffer={true}
    >
      <AttributionControl compact={true} position="bottom-left" />
      <NavigationControl showCompass={false} position="bottom-right" />
      <Source
        id="admin"
        promoteId="id"
        type="vector"
        tiles={[
          'https://d3m2jtl1i4hi9v.cloudfront.net/merged_4326/{z}/{x}/{y}.pbf',
        ]}
      />
      <Image
        image={MapBrandContainedIcon}
        key="custom-marker"
        id={'custom-marker'}
      />

      <Source
        id="search"
        type="geojson"
        data={
          searchMode
            ? {
                type: 'FeatureCollection',
                features: searchData.map((place) => {
                  return {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: place.coord,
                    },
                    properties: {
                      title: place.title,
                      label: Math.round(place.score * 100) + '%',
                    },
                  };
                }),
              }
            : null
        }
      />
      <Source
        id="points"
        type="geojson"
        data={
          siteProfileMode
            ? {
                type: 'FeatureCollection',
                features: sites.map((site) => {
                  return {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: site.coord,
                    },
                    properties: {
                      title: site.employers
                        .map((employer) => employer)
                        .join('\n'),
                      employers: site.employers,
                    },
                  };
                }),
              }
            : null
        }
      />
      <Source id="values" type="geojson" data={valuesToDisplay} />
      <Layers />
      <MapContext.Consumer>
        {(map: any) => {
          return (
            <FeatureAdder
              map={map}
              setter={setValuesToDisplay}
              // loaded={loaded}
            />
          );
        }}
      </MapContext.Consumer>
    </MapGL>
  );
  return (
    <div className="h-full w-full min-h-[500px]">
      {/* div classname for debug */}
      {map_gl}
    </div>
  );
}

export default MapComponent;

function FeatureAdder({
  map,
  setter,
}: // loaded,
{
  map: Map;
  setter?: Function;
  // loaded: boolean;
}) {
  const filters = useFilterValues();
  const [firstRender, setFirstRender] = useState(true);
  const areaComp = useAreaCompData();
  const viewport = useRecoilValue(recoilViewport);
  const allAreas = useGetAllAreasByTypeQuery({
    variables: { area_type: filters.comparedGeography?.value },
  }).data?.areas;
  const [featLayer, setFeatLayer] = useState('' as string);
  const [sourceFeatLayer, setSourceFeatLayer] = useState('' as string);

  const [loaded, setLoaded] = useState(false);
  switch (filters.comparedGeography?.value) {
    case 'nation':
      if ('nations-fill' !== featLayer) {
        setSourceFeatLayer('Nations_4326');
        setFeatLayer('nations-fill');
      }
      break;
    case 'region':
      if ('regions-fill' !== featLayer) {
        setSourceFeatLayer('Regions_4326');
        setFeatLayer('regions-fill');
      }
      break;
    case 'canoncounty':
      if ('counties-fill' !== featLayer) {
        setSourceFeatLayer('Counties_4326');
        setFeatLayer('counties-fill');
      }
      break;
    case 'lad':
      if ('lads-fill' !== featLayer) {
        setSourceFeatLayer('LADs_4326');
        setFeatLayer('lads-fill');
      }
      break;
  }
  const areaFeatures = map.queryRenderedFeatures(undefined, {
    layers: [featLayer],
  });
  console.log('area feat', areaFeatures, featLayer);
  const add = () => {
    console.log('adding features ');
    let data = {
      type: 'FeatureCollection',
      features: [],
    } as T;

    //takes one polygon per area and calculates the centroid
    areaFeatures
      .filter((feature) => feature.sourceLayer === sourceFeatLayer)
      .map((feature) => {
        // check if is not added yet
        if (
          data.features.filter(
            (f) => f.properties?.id === feature.properties?.id
          ).length === 0
        )
          data.features.push({
            type: 'Feature',
            properties: {
              id: feature.properties?.id,
              fid: feature.properties?.fid,
              name: feature.properties?.name,
              value:
                Math.round(
                  areaComp.data.filter(
                    (area) => area.areaCode === feature.properties?.id
                  )?.[0]?.data * 100
                ) / 100, //feature.state?.value,
              polygon: feature.geometry,
            },
            geometry: centroid(feature.geometry as AllGeoJSON).geometry,
          } as GeoJSON.Feature<GeoJSON.Point>);
      });
    data.features.map((dispfeat) => {
      // for each feature with same id but a different geometry, properties.polygon = the union of this geometry and the properties.polygon
      areaFeatures
        .filter(
          (f) =>
            f.properties?.id === dispfeat.properties?.id &&
            centroid(f.geometry as AllGeoJSON).geometry !== dispfeat.geometry
        )
        .map((f) => {
          if (dispfeat.properties)
            dispfeat.properties.polygon = union(
              f.geometry as Polygon,
              dispfeat.properties?.polygon as Polygon
            )?.geometry;
        });
      dispfeat.geometry = centroid(
        dispfeat.properties?.polygon as AllGeoJSON
      ).geometry;
    });

    setter?.(data);
    console.log('values to display', data);
    // }
  };

  useEffect(() => {
    map.flyTo({
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
    });
    if (loaded && areaFeatures.length > 0) add();
  }, [map, loaded, firstRender]);
  // useEffect(() => {
  useEffect(() => {
    map.on('click', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [featLayer],
      });
    });
    if (!areaComp.loading) {
      areaComp.data.map((area) => {
        if (area.areaCode) {
          map.setFeatureState(
            {
              source: 'admin',
              sourceLayer: sourceFeatLayer, //area.unit==='lad'?'lads':area.unit==='region'?'regions':'countries',
              id: area.areaCode,
            },
            {
              value: area.data,
            }
          );
        }
      });
      // we should better map all the areas and give them 0 as value, unless they are in areaCompdata
      allAreas?.map((areaOnTheMap) => {
        // set 0 as value for everything
        map.setFeatureState(
          {
            source: 'admin',
            sourceLayer: sourceFeatLayer,
            id: areaOnTheMap.id,
          },
          {
            value: 0,
          }
        );
        // if areaOnTheMap is in areaCompData do:
        areaComp.data
          .filter(
            (areaOntheChart) => areaOntheChart.areaCode === areaOnTheMap.id
          )
          .map((area) => {
            map.setFeatureState(
              {
                source: 'admin',
                sourceLayer: sourceFeatLayer, //area.unit==='lad'?'lads':area.unit==='region'?'regions':'countries',
                id: area.areaCode,
              },
              {
                value: area.data,
              }
            );
          });
      });
      // setLoaded(Math.round(Math.random() * 1000));
      setLoaded(true);
      console.log('loaded', areaComp.data, sourceFeatLayer);
      if (areaFeatures.length === 0 && firstRender) {
        console.log('firstrender');
        //HACKY WAY *bad* :(
        rerender();
      }
    } else {
      setLoaded(false);
    }
  }, [
    map,
    areaComp,
    filters.comparedGeography?.value,
    filters.restrictToArea?.id,
    filters.restrictToArea?.area_type,
  ]);
  const rerender = async () => {
    await setTimeout(1700);
    console.log('rerendering');
    setFirstRender(false);
  };
  return <></>;
}
