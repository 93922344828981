import {
  DetailedHTMLProps,
  DetailedReactHTMLElement,
  HTMLAttributes,
  ReactNode,
} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

function TitleWithHR({
  as = 'span',
  className = 'flex',
  children,
  ...props
}: {
  as: String;
  className: String;
  children: ReactNode;
}) {
  const CustomTag = `${as}` as keyof JSX.IntrinsicElements;
  return (
    <div className={`flex flex-row items-center gap-2 ${className}`}>
      <CustomTag {...props}> {children}</CustomTag>
      <hr className={`border-t-1 h-px w-full mt-1 border-current opacity-30`} />
    </div>
  );
}

export default TitleWithHR;

export function LoadingSpinner() {
  return (
    <div className="absolute flex flex-col items-center top-1/2 left-1/2">
      Loading...
      <ClipLoader />
    </div>
  );
}
export function NoData() {
  return (
    <div className="absolute flex flex-col items-center text-center top-1/2 left-1/2">
      No data
    </div>
  );
}
