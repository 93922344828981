import { useRecoilState, useRecoilValue } from 'recoil';

import SearchSettings from '../SearchSettings';
import {
  recoilAdType,
  recoilPostsToDisplayOnMap,
  recoilProvider,
  recoilRestrictAdToArea,
  recoilSearchText,
  recoilSelectedSearchTab,
  recoilTimePeriod,
} from '../atoms';
// import { IconButton } from 'components/Input';

import { recoilViewport } from 'components/AnalyticsPanel/VisualTab/atoms';
import { useEffect, useState } from 'react';
import Linkify from 'react-linkify';
import { geographicArea } from 'Utils';
import {
  coursesAPIEndpoint,
  jobsAPIEndpoint,
} from 'version_variables/version_variables';
import JobAd from './JobAd';
import { empty } from '@apollo/client';
import ClipLoader from 'react-spinners/ClipLoader';
import CourseAd from './CourseAd';
// import { IconButton } from '@mui/material';
// async function fetchAPI(searchTerm: string) {
//   const res = await fetch('http://52.56.134.220:5000/lightcast', {
//     body: 'text=' + searchTerm,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     method: 'POST',
//   });
//   return res;
// }


function linkify(text: string | null) {
  if (!text) return '';
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return '<a href="' + url + '">' + url + '</a>';
  });
}

export type jobData = {
  AvgAnnualSalary: string | null;
  CanonCity: string | null;
  CanonCounty: string | null;
  CleanJobTitle: string | null;
  JobDate: string | null;
  JobText: string | null;
  Latitude: string | null;
  Longitude: string | null;
  LocalAuthorithyDistrict: string | null;
  Nation: string | null;
  Region: string | null;
  Score: number;
  Metadata: string | null;
  Sentences: string[] | null;
  SentenceScores: number[][] | null;
};
export type courseData = {
  Course: string | null;
  Description: string | null;
  Metadata: {
    GPS: string | null;
    Lat: number;
    Lon: number;
    Provider: string | null;
    URL: string | null;
  } | null;
  Score: number;
  Sentences: string[] | null;
  SentenceScores: number[][] | null;
};
const emptyJobData = [] as jobData[];
const emptyCourseData = [] as courseData[];
export const one_day = 1000 * 60 * 60 * 24; // 1 day in milliseconds

async function fetchJobData(searchTerms: string) {
  const res = await fetch(jobsAPIEndpoint, {
    body: 'text=' + searchTerms,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  });
  return res.json();
}

function ResultTab() {
  // const posts = useRecoilValue(recoilPosts);
  const [mapviewport, setMapViewport] = useRecoilState(recoilViewport);
  const [selectedTab, setSelectedTab] = useRecoilState(recoilSelectedSearchTab);
  const adType = useRecoilValue(recoilAdType);
  const timePeriod = useRecoilValue(recoilTimePeriod);
  const area = useRecoilValue(recoilRestrictAdToArea);
  const [loading, setLoading] = useState(false);
  const provider = useRecoilValue(recoilProvider);
  const searchTerms = useRecoilValue(recoilSearchText);
  const [searchJobData, setSearchJobData] = useState(emptyJobData);
  const [searchCourseData, setSearchCourseData] = useState(emptyCourseData);
  const [postsToDisplayOnMap, setPostsToDisplayOnMap] = useRecoilState(
    recoilPostsToDisplayOnMap
  );
  // console.log('fetch', fetchAPI('Electricity'));

  useEffect(() => {
    (async () => {
      if (searchTerms) {
        try {
          setLoading(true);
          const json = ((await fetchJobData(searchTerms)) ||
            emptyJobData) as jobData[];
          setSearchJobData(json);
          setLoading(false);
          console.log('json', json[0]);

          // console.log('json', json);
        } catch (err) {
          setSearchJobData(emptyJobData);
        }
        try {
          const res = await fetch(coursesAPIEndpoint, {
            body: 'text=' + searchTerms,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
          });
          const json = ((await res.json()) || emptyCourseData) as courseData[];
          setSearchCourseData(json);
          // console.log('json', json[0]);
        } catch (err) {
          setSearchCourseData(emptyCourseData);
        }
      }
    })();
  }, [searchTerms]);
  useEffect(() => {
    if (adType === 'Courses') {
      setPostsToDisplayOnMap(
        searchCourseData
          .filter((post) => post.Metadata?.Lat && post.Metadata?.Lon)
          .map((post) => ({
            title: post.Course || 'Untitled',
            score: post.Score || 0,
            description: post.Description || 'No description',
            url: post.Metadata?.URL || undefined,
            coord: [post.Metadata?.Lon || 0, post.Metadata?.Lat || 0],
            subtitle: post.Metadata?.Provider || 'Unknown provider',
          }))
      );
    }
    if (adType === 'Jobs') {
      setPostsToDisplayOnMap(
        searchJobData
          .filter((post) => post.Latitude && post.Longitude)
          .map((post) => ({
            title: post.CleanJobTitle || 'Untitled',
            score: post.Score || 0,
            description: post.JobText || 'No description',
            url: undefined,
            coord: [
              parseInt(post.Longitude || '0'),
              parseInt(post.Latitude || '0'),
            ],
            subtitle: post.JobDate
              ? new Date(post.JobDate).toLocaleDateString() + ' - '
              : '' + post.AvgAnnualSalary
              ? Math.round(parseInt(post.AvgAnnualSalary || '0') / 1000) + 'K £'
              : '',
            // sentences: post.Sentences || [],
          }))
      );
    }
  }, [searchCourseData, searchJobData]);
  useEffect(() => {
    console.log('posts to display onmap', postsToDisplayOnMap);
  }, [postsToDisplayOnMap]);
  // console.log('fetch: ', searchData);

  return (
    <div className="h-full relative flex-col">
      <SearchSettings />
      {/* <SearchOptionBar /> */}
      {loading ? (
        <div className="flex flex-row justify-center py-5">
          <div className="flex flex-col items-center gap-5">
            <span>Loading ...</span>
            <ClipLoader />
          </div>
        </div>
      ) : (
        <></>//no result
      )}
      {adType === 'Jobs' ? (
        <div className="flex flex-col p-5 gap-5 mb-96">
          {searchJobData.length > 0 ? (
            searchJobData.map((post, idx) => {
              const howLongAgo = //how many days ago
                post.JobDate === null
                  ? null
                  : Math.round(
                      new Date().getTime() / one_day -
                        new Date(post.JobDate).getTime() / one_day
                    );

              if (howLongAgo === null || howLongAgo <= timePeriod.maxDays)
                return <JobAd post={post} preload={idx<3} key={idx+ (post?.CleanJobTitle?.slice(0,10)||'untitled')} />;
            })
          ) : (
            <></>
          )}
        </div>
      ) : adType==='Courses'? (
      <div className="flex flex-col p-5 gap-5 ">
        {searchCourseData.length > 0 ? (
          searchCourseData.map((post, idx) => {
            // return <div key={idx}>{idx}</div>
            return <CourseAd post={post} preload={idx<3} key={idx+ (post?.Course?.slice(0,10)||'untitled')}/>
          }
        )
        ) : (
          <></>//No result
        )}
      </div>
      ): <></>}
    </div>
  );
}
export const JobAdDescription = ({ fullText }: { fullText: string }) => {
  // console.log(fullText);
  const [readMore, setReadMore] = useState(false);
  //leave only two \n if we have several in a row
  fullText = fullText.replace('\r', '\n');
  fullText = fullText.replace(/(\n){3,}/g, '\n\n');
  const displayedText = readMore ? fullText : fullText.slice(0, 600) + '...';
  return (
    <div className="flex flex-col gap-1 items-start py-5">
      <p className="whitespace-pre-line jobad max-w-4xl text-sm">
        <Linkify
          properties={{ class: 'aa', target: '_blank' }} //not working
        >
          {displayedText}
        </Linkify>
      </p>
      {fullText.length > 600 ? (
        <button
          className="font-medium hover:underline"
          onClick={() => setReadMore(!readMore)}
        >
          Read {readMore ? 'Less' : 'More'}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};
// const isInsideRestrictedArea = (
//   //to filter we'll need geotree with lad names and or county name be more aware of if it's going to be null or not
//   AdCounty: string,
//   restrictedArea: geographicArea
// ) => {
//   if (restrictedArea === null) return true; // if no restriction then true
//   //then let's take the most restricted area info of the ad -> LAD
//   //let's check restricted area's unit
//   //first let's find the row of this LAD in the geotree, with flexible compare. let's do two compare one with where area like %term%, and another one with where area in terms.slice
//   //then let's check if the field corresponding to areas unit contains the areacode
// };
export default ResultTab;
