import styles from '../styles/pages/Privacy.module.css';

const Privacy = () => {
  return (
    <div className={styles.container}>
      <div className={`${styles.card} p-5 text-neutral-20 font-medium privacy`}>
        <h2>Oxford Data Technology Limited Privacy Notice</h2>
        <p>
          This Notice sets out information about how we use, store and transfer
          personal data which we receive in connection with your registered use
          of one of our proprietary webtools.
        </p>
        <p>
          If you are not a registered user of our webtool, and have some other
          relationship with us, then our use of your personal data would be
          covered in our general privacy notice on our website at:{' '}
          <a about="_blank" href="www.oxdatatech.com">
            oxdatatech.com
          </a>
          .
        </p>
        <p>
          This Notice also contains information about our use of cookies. We
          will ask you to consent to our use of cookies (or select which cookies
          you accept) in accordance with the terms of this Notice when you first
          visit our website.
        </p>
        <p>
          <strong>We</strong> or <strong>us</strong> means Oxford Data
          Technologies Limited, a UK limited company (number 14383330) whose
          offices are at Harwood House, 43 Harwood Road, London, United Kingdom,
          SW6 4QP.
        </p>
        <div>
          <h3>Summary</h3>
          Full details are set out in the relevant sections of this Notice
          below, but in brief:
          <ul>
            <li>
              we are a data controller in relation to the personal data that we
              hold. That means we determine how we will use and look after it;
            </li>
            <li>we receive your personal data from you;</li>
            <li>
              we use your personal data to deliver our services, conduct our
              business, keep appropriate records and meet our legal obligations;
            </li>
            <li>
              we only provide your personal data to third parties for our
              business purposes or as permitted by law. We don’t share your data
              with third party advertisers;
            </li>
            <li>we store personal data for specified periods;</li>
            <li>
              you have legal rights in relation to your personal data which you
              can exercise on request;
            </li>
            <li>our websites use cookies;</li>
            <li>
              you can contact us to enquire about any of the contents of this
              Notice.
            </li>
          </ul>
          <ul>
            <li>
              <h4>Personal Data we collect</h4>
              <p>
                In this Section we have set out the kinds of personal data that
                we may collect, use, store and transfer when you apply to
                register as a user of one of our webtools, or when you use that
                webtool . We have grouped that data together into different
                categories based on subject matter.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
