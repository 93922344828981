import {
  ClassAttributes,
  HTMLAttributes,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
// import '../../../styles/output.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { PlayIcon, PauseIcon } from '@heroicons/react/outline';
import Ticker from 'react-awesome-ticker';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  useGetCareerQuery,
  // useGetCountsAndPropsQuery,
  useGetGlobalCountsAndPropsQuery,
  useGetMaxYearQuery,
  useGetTermsQuery,
  // useGetYearsQuery,
} from '../../generated/graphql';
import { Series } from 'data-forge';
import { JobDateToYear, shortenString } from 'Utils';
import { recoilIndustry, recoilCategory } from './VisualTab/atoms';
import { get } from 'http';

// const useTerms = () => {
//   const termCategory = useRecoilValue(recoilCategory);
//   const allManuallySelectedTerms = useGetTermsQuery();
//   // if (!allManuallySelectedTerms.loading)
//   switch (termCategory.name) {
//     case 'Job role':
//       return allManuallySelectedTerms.data?.all_job.map(
//         (term) => term.Label || ''
//       ) as string[];
//     case 'Skill':
//     default:
//       return allManuallySelectedTerms.data?.all_job.map(
//         (term) => term.Label || ''
//       ) as string[];
//   }
// };

const useCountsAndProps = (year: number) => {
  // const terms = useTerms();
  const industry = useRecoilValue(recoilIndustry);
  const category = useRecoilValue(recoilCategory);

  //Find the most popular jobs for this year
  return useGetGlobalCountsAndPropsQuery({
    variables: {
      year: year,
      source: industry,
      category: category.value,
    },
  }).data;
};
function TickerBar() {
  const termCategory = useRecoilValue(recoilCategory);
  const industry = useRecoilValue(recoilIndustry);
  //now is our year, we'll not take this year into account as it not yet finished
  const lastYear = new Date().getFullYear() - 1;

  const getMaxYear = useGetMaxYearQuery({
    variables: { source: industry, category: termCategory.value },
  });
  //Find the most recent year in the table (or last year if it happens to be the current year)
  const currentYear = Math.min(
    lastYear,
    getMaxYear.data?.max_year?.aggregate?.max?.year as number
  );
  // console.log(
  //   'ticker year',
  //   termCategory.value,
  //   industry,
  //   getMaxYear.data?.max_year?.aggregate?.max?.year
  // );

  const currentYearData = useCountsAndProps(currentYear);
  const pastYearData = useCountsAndProps(currentYear - 1);

  //Join the current and past year data together and calculate the change and percentage change
  const tickerList = new Series(currentYearData?.all_counts)
    //joins current years counts with current year props
    .join(
      new Series(currentYearData?.all_props),
      (innerRow) => innerRow.label,
      (innerRow) => innerRow.label,
      (countsRow, propsRow) => {
        return {
          label: countsRow.label,
          count: countsRow.amount,
          proportion: propsRow.amount,
        };
      }
    )
    //joins current years counts and props with past years counts and props
    .join(
      new Series(pastYearData?.all_counts)
        //joins past years counts with past year props
        .join(
          new Series(pastYearData?.all_props),
          (innerRow) => innerRow.label,
          (innerRow) => innerRow.label,
          (countsRow, propsRow) => {
            return {
              label: countsRow.label,
              count: countsRow.amount,
              proportion: propsRow.amount,
            };
          }
        ),

      (innerRow) => innerRow.label,
      (innerRow) => innerRow.label,
      (currentYearRow, pastYearRow) => {
        return {
          Label: shortenString(currentYearRow.label || '', 40),
          countChange: (currentYearRow.count || 0) - (pastYearRow.count || 0),
          countPercentChange: toPercentage(
            pastYearRow.count || 0,
            currentYearRow.count || 0
          ),
          proportionChange:
            Math.round(
              ((currentYearRow.proportion || 0) -
                (pastYearRow.proportion || 0)) *
                100
            ) / 100, // round to 0.00
          proportionPercentChange: toPercentage(
            pastYearRow.proportion || 0,
            currentYearRow.proportion || 0
          ),
        };
      }
    )
    .toArray();

  const [playTicker, setPlayTicker] = useState(true); //false if ticker is paused

  // maximum width is set in css files
  return (
    <div className="w-full flex flex-row py-5 px-5 gap-4 items-center overflow-x-hidden tickerbar z-10 bg-neutral-90">
      <div
        className="flex flex-row align-middle gap-3 text-sm font-medium"
        data-tooltip-id="ticker-tooltip"
        data-tooltip-content="Count change (%)  ·  Proportion change (%)"
      >
        <div className="flex flex-col">
          <span className="text-center text-lg whitespace-nowrap">
            {termCategory.name} Ticker
          </span>
          <span className="text-sm font-light text-center text-neutral-20 whitespace-nowrap ">
            {`${currentYear - 1} - ${currentYear}`}
          </span>
          <span className="text-sm text-center font-light text-neutral-20 whitespace-nowrap ">
            {industry}
          </span>
        </div>
        <ReactTooltip id="ticker-tooltip" />
        <div className="mx-auto border border-brand rounded-lg">
          <IconButton
            className="w-12 p-0"
            style={{ borderRadius: '0px' }}
            onClick={() => {
              setPlayTicker(!playTicker);
            }}
          >
            {playTicker ? (
              <PlayIcon className="w-full" color="var(--color-brand)" />
            ) : (
              <PauseIcon className="w-full" color="var(--color-brand)" />
            )}
          </IconButton>
        </div>
      </div>
      <div
        className="tickerlist flex-1 bg-neutral-90"
        data-tooltip-id="ticker-tooltip"
        data-tooltip-content="Count change (%)  ·  Proportion change (%)"
      >
        <Ticker play={playTicker}>
          {tickerList.map((tickerMeta, k) => (
            <TickerElement key={k} {...tickerMeta} />
          ))}
        </Ticker>
      </div>
    </div>
  );
}

function TickerElement({
  Label,
  countChange,
  countPercentChange,
  proportionChange,
  proportionPercentChange,
}: {
  Label: string;
  countChange: number;
  countPercentChange: number | undefined;
  proportionChange: number;
  proportionPercentChange: number | undefined;
}) {
  return (
    <div className="flex flex-col flex-shrink-0 text-sm text-center p-3 rounded mr-5 bg-ticker-bg text-text-light">
      <span>{Label}</span>
      <div className="flex flex-row justify-around gap-2 text-sm">
        {countChange > 0 ? (
          <span className="text-success">
            <ArrowDropUpIcon />
            {`+`}
            {countChange}
            {`(+`}
            {countPercentChange}
            {`%)`}
          </span>
        ) : countChange < 0 ? (
          <span className="text-warning">
            <ArrowDropDownIcon />
            {``}
            {countChange}
            {`(`}
            {countPercentChange}
            {`%)`}
          </span>
        ) : (
          <span className="text-text-light">--</span>
        )}
        {proportionChange > 0 ? (
          <span className="text-success">
            <ArrowDropUpIcon />
            {`+`}
            {proportionChange}
            {proportionPercentChange === undefined ? '' : `(+`}
            {proportionPercentChange}
            {`%)`}
          </span>
        ) : proportionChange < 0 ? (
          <span className="text-warning">
            <ArrowDropDownIcon />
            {``}
            {proportionChange}
            {proportionPercentChange === undefined ? '' : `(`}
            {proportionPercentChange}
            {`%)`}
          </span>
        ) : (
          <span className="text-gray-400">--</span>
        )}
      </div>
    </div>
  );
}

function toPercentage(firstValue: number, secondValue: number) {
  //first value is the past year, second value is the current year

  if (firstValue === 0) {
    return undefined;
  }

  return Math.round((100 * (secondValue - firstValue)) / firstValue);
}

export default TickerBar;
