import Button from "@mui/material/Button";
import { courseData } from ".";
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import { JobAdDescription, jobData, one_day } from '.';
import { recoilViewport } from "components/AnalyticsPanel/VisualTab/atoms";
import { useRecoilState } from "recoil";
import { recoilSelectedSearchTab } from "../atoms";
import { useEffect, useRef, useState } from "react";
import CompressIcon from '@mui/icons-material/Compress';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { Configuration, OpenAIApi } from "openai";
import ClipLoader from "react-spinners/ClipLoader";
import EngineeringIcon from '@mui/icons-material/Engineering';
import GPTFeatures from "./GPTFeatures";
import CompareFeature from "./CompareFeature";



function CourseAd({ post, preload=false}: { post: courseData, preload?: boolean}) {
    const [mapviewport, setMapViewport] = useRecoilState(recoilViewport);
    const [selectedTab, setSelectedTab] = useRecoilState(recoilSelectedSearchTab);
    
    // console.log('key:', key);
    return   (
      <div 
        className="justify-between flex flex-row py-5 gap-5 rounded-lg bg-custom-white  border-brand custom-black hover:bg-neutral-color-20 hover:border hover:shadow-md shadow-brand"
        >
        <div className="flex flex-col gap-5 items-start grow pl-5 max-w-5xl">
          <span className="flex flex-row gap-5">
              <h3 className="text-xl font-bold">{post.Course || '--'}</h3>
              <div className="flex flex-row gap-1 items-center text-neutral-20">
              <span>Percent matched:</span>
              <div
                  className={`text-xl font-bold 
                  ${
                      post.Score > 0.65
                      ? 'text-success'
                      : post.Score > 0.5
                      ? 'text-okayish'
                      : 'text-mediocre'
                  }`}
              >
                  {Math.round(post.Score * 100)}%
              </div>
              </div>
              {post.Metadata != null && post.Metadata.URL != null ? (
              <Button
                  sx={{
                  width: 32,
                  height: 32,
                  '& .MuiButton-startIcon': { margin: 0 },
                  }}
                  variant="outlined"
                  startIcon={<LanguageIcon />}
                  onClick={() =>
                  window.open(post.Metadata?.URL || '#', '_blank')
                  }
              />
              ) : (
              <></>
              )}
          </span>
          {post.Metadata != null && post.Metadata.Provider != null ? (
              <div className="flex flex-row text-neutral-20 gap-5 items-end pt-2">
              {/* <span>{post.Metadata.Provider}</span> */}

              {post.Metadata.Lat != null && post.Metadata.Lon != null ? (
                  <Button
                  sx={{
                      width: 32,
                      height: 32,
                      '& .MuiButton-startIcon': { margin: 0 },
                  }}
                  variant="outlined"
                  startIcon={<PlaceIcon />}
                  onClick={() => {
                      setMapViewport({
                      latitude: post.Metadata?.Lat || 53.52,
                      longitude: post.Metadata?.Lon || -1.13,
                      zoom: 10.5,
                      });
                      setSelectedTab(1);
                  }}
                  ></Button>
              ) : (
                  <></>
              )}
              <span>{post.Metadata.Provider}</span>
              </div>
          ) : (
              <></>
          )}
          <JobAdDescription fullText={post.Description || ''} />
          </div>
        <div
          className='top-5 flex flex-col gap-5 items-end' >
          <GPTFeatures title={post.Course||''} description={post.Description||''} preload={preload}/>
          {/* <CompareFeature post={{Sentences:post.Sentences, SentenceScores:post.SentenceScores}}/> */}
        </div>
        
      </div>)
    ;
}

export default CourseAd;