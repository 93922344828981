import MapComponent from 'components/AnalyticsPanel/VisualTab/AreaComparison/components/Map';
import SearchOptionBar from '../SearchOptionsBar';
import { recoilPostsToDisplayOnMap, recoilSelectedPost } from '../atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { JobAdDescription } from '../ResultTab';
import Button from '@mui/material/Button';
import PlaceIcon from '@mui/icons-material/Place';
import { recoilViewport } from 'components/AnalyticsPanel/VisualTab/atoms';
function MapTab() {
  const selectedPostTitle = useRecoilValue(recoilSelectedPost);
  const postsdisplayed = useRecoilValue(recoilPostsToDisplayOnMap);
  const selectedPost = postsdisplayed.filter(
    (post) => post.title === selectedPostTitle
  )?.[0];
  const [mapViewport, setMapViewport] = useRecoilState(recoilViewport);

  return (
    <div className="flex flex-col h-full relative ">
      {/* <SearchOptionBar /> */}
      {selectedPost ? (
        <div className="absolute top-5 left-5 w-1/3 p-5 z-50 max-h-[90%] rounded-lg bg-custom-white shadow-md flex flex-col">
          <div className="flex flex-col">
            <div className="text-base text-custom-black font-semibold">
              {selectedPost.title}
            </div>
            <div className="text-sm text-neutral-20 font-medium flex flex-row justify-between pt-5">
              <div className="flex flex-col">
                <span>
                  Percent matched:{Math.round(selectedPost.score * 100) + '%'}
                </span>
                <div className="text-neutral-20 text-sm">
                  {selectedPost.subtitle}
                </div>
              </div>
              <Button
                sx={{
                  width: 32,
                  height: 32,
                  '& .MuiButton-startIcon': { margin: 0 },
                }}
                variant="outlined"
                startIcon={<PlaceIcon />}
                onClick={() => {
                  setMapViewport({
                    latitude: selectedPost.coord[1],
                    longitude: selectedPost.coord[0],
                    zoom: 10.5,
                  });
                }}
              ></Button>
            </div>
          </div>
          <div className="text-sm overflow-auto">
            <JobAdDescription fullText={selectedPost.description} />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="flex-grow w-full">
        <MapComponent searchMode />
      </div>
    </div>
  );
}

export default MapTab;
