import { Combobox, Tab, TabProps } from '@headlessui/react';
import styles from '../styles/components/Input.module.css';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SvgIconTypeMap } from '@mui/material';
const Input = ({ type = 'text', label = '', ...props }) => {
  return (
    <div className={styles.container}>
      {label ? <label className={styles.label}>{label}</label> : null}
      <input type={type} className={styles.input} {...props} />
    </div>
  );
};

export function CustomComboBox({
  filterValue,
  filterValueSetter,
  options,
}: {
  filterValue: any;
  filterValueSetter: (value: any) => void;
  options: any[];
}) {
  return (
    <div className="relative">
      <Combobox value={filterValue.label} onChange={filterValueSetter}>
        {/* the input displayed */}
        <Combobox.Input className="w-full border rounded border-1 px-5 py-3 text-base border-gray-300 " />

        {/* the combo button */}
        <Combobox.Button className="absolute inset-y-0 top-2 right-0 flex items-center pr-2 h-9">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {/* the options on dropdown */}
        <Combobox.Options className="absolute w-full flex flex-col mt-1 rounded  max-h-60">
          {options.map((opt) => (
            <Combobox.Option key={opt.label} value={opt}>
              {({ active, selected }) => (
                <li
                  key={` li ${opt.label}`}
                  className={`px-2 py-1 cursor-pointer ${
                    active
                      ? 'bg-blue-500 text-white rounded'
                      : 'bg-slate-100 text-black opacity-80'
                  }`}
                >
                  {opt.label}
                </li>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
}

export const TextAreaInput = ({
  //this isn't actually used
  className = 'flex',
  rows,
  placeholder,
  ...props
}: {
  className: String;
  rows: number;
  placeholder: String;
}) => {
  return (
    <textarea
      className={`${className} border rounded border-1 w-full border-gray-300`}
      rows={rows}
      {...props}
    ></textarea>
  );
};

export function SubmitButton(props: ButtonHTMLAttributes<Element>) {
  return (
    <button
      type="button"
      className="submit text-custom-white px-8 bg-brand rounded-full 
      hover:bg-gradient-to-br focus:ring-4 focus:outline-none 
      focus:ring-blue-300 dark:focus:ring-blue-800 font-medium py-4 text-center"
      {...props}
    ></button>
  );
}

export function BorderedSection({
  icon = undefined,
  title,
  className = '',
  children,
}: {
  icon?: ReactNode;
  title: String;
  className?: String;
  children: ReactNode;
}) {
  return (
    <div className={`${className}`}>
      <div className="z-10 relative">
        <div className={styles.headerBorderBefore}></div>
        {(icon || title) && (
          <div className="bg-white ml-2 -mb-2 w-fit px-1">
            {icon}
            {title && <span className="text-sm">{title}</span>}
          </div>
        )}
        <div className={styles.headerBorderAfter}></div>
      </div>
      <div className="z-0 relative">{children}</div>
    </div>
  );
}

// export function IconButton({
//   icon,
//   className = '',
//   ...props
// }: {
//   icon: SvgIconTypeMap; //ReactNode;
//   className?: string;
//   props: ButtonHTMLAttributes<Element>;
// }) {
//   return (
//     <button type="button" {...props}>
//       {icon}
//     </button>
//   );
// }
export function ToggleTab(props: any): JSX.Element {
  //q: what should i write for the argument of Tabprops? a:
  return (
    <Tab
      className="w-full py-2.5 px-2.5 text-base font-medium leading-5 
      rounded-lg
      ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 
      focus:outline-none
      ui-selected:bg-brand 
      ui-selected:text-white
      ui-not-selected:bg-white 
      ui-not-selected:text-brand"
    >
      {props.children}
    </Tab>
  );
}

// export function IconToggleButton(props: any): JSX.Element {
//   return <>

//   </>;
// }

// export function ExpandButton(
//   props: ButtonHTMLAttributes<Element>
// ): JSX.Element {
//   return <button type="button" {...props}></button>;
// }

export default Input;
