import { atom, useRecoilValue } from 'recoil';
import { QueryResult } from '@apollo/client/react/types/types';
import { geographicArea as GeographicArea } from 'Utils';
import { dataType } from './AreaComparison';
import {
  categoryOptions,
  defaultTerms,
  industryList,
  termGroupOptions,
} from 'version_variables/version_variables';

export const defaultViewPort = {
  latitude: 53.76854766536943,
  longitude: -3.4785731259827454,
  zoom: 4.5,
};

export const recoilViewport = atom({
  key: 'viewport',
  default: defaultViewPort,
});

export type CountByYear = {
  JobDate: string;
  Label: string;
  Count: number;
  // id: number;
};
export type TotalByArea = {
  Area: string;
  Total: number;
};

// export const TermCategories = [
//   {
//     name: 'Skill',
//     terms: ['No data available'],
//   },
//   {
//     name: 'Job role',
//     terms: ['No data available'],
//   },
// ] as { name: 'Skill' | 'Job role' | 'Technology'; terms: string[] }[];

type analyticLabel = 'Employer Demand' | 'Market Value';
export const AnalyticTabs = [
  {
    index: 0,
    label: 'Employer Demand' as analyticLabel,
    measureOpt: [
      { name: 'Counts', value: 'counts' },
      { name: 'Counts Change', value: 'counts' },
      { name: 'Proportions', value: 'proportions' },
      { name: 'Proportions Change', value: 'proportions' },
    ] as measureType[],
  },
  {
    index: 1,
    label: 'Market Value' as analyticLabel,
    measureOpt: [
      { name: 'Average salaries', value: 'salary' },
    ] as measureType[],
  },
] as { index: number; label: string; measureOpt: measureType[] }[];
export type GeographicSize = {
  name: string;
  value: 'nation' | 'region' | 'canoncounty' | 'lad';
};

export const comparedGeographyAllOpt = [
  { name: 'Nation', value: 'nation' },
  { name: 'Region', value: 'region' },
  { name: 'County', value: 'canoncounty' },
  { name: 'Local Authority District', value: 'lad' },
] as GeographicSize[];

export const recoilComparedGeographyAvailOpt = atom({
  key: 'comparative options',
  default: comparedGeographyAllOpt,
});

export const recoilQueryLabels = atom({
  key: 'queryLabels',
  default: AnalyticTabs.map((tab) => tab.measureOpt[0]), //queryLabel is an array with one query label per tab. The default is the first query of each tab
});

export const recoilAnalyticTab = atom({
  key: 'analyticTab',
  default: AnalyticTabs[0],
});
export type termGroupType = {
  name: string;
  value: 'skill' | 'job' | 'tech' | 'clusters' | 'emergent';
};

export const recoilTermGroup = atom({
  key: 'termgroup',
  default: termGroupOptions[categoryOptions[0].value][0] as termGroupType,
});
export type categoryType = { name: string; value: 'skill' | 'job' };

export const recoilCategory = atom({
  key: 'category',
  default: categoryOptions[0] as categoryType,
});

export type measureType = {
  name: string;
  value: 'counts' | 'proportions' | 'salary';
};

export const recoilMeasure = atom({
  key: 'measure',
  default: AnalyticTabs[0].measureOpt[0] as measureType,
});

export const recoilTerms = atom({
  key: 'recoil terms',
  default: defaultTerms as string[], //undefined as undefined | Options<unknown>,
});
export const recoilFromYearToYear = atom({
  key: 'from year X to year Y',
  default: [2012, 2022] as number[],
});

export const recoilRestrictToArea = atom({
  key: 'area',
  default: null as GeographicArea, //'', //areas[0],
});

export const recoilComparedGeography = atom({
  key: 'compared geography',
  default: comparedGeographyAllOpt[0] as GeographicSize, //| undefined,
});

export const defaultData = [
  {
    key: 'No data',
    data: 0,
  },
] as dataType;

export const recoilIndustry = atom({
  key: 'industry',
  default: industryList[0].value as string,
});

export const recoilFilterInstance = atom({
  key: 'filter instance',
  default: 0,
});
