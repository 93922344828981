// import AnalyticsPanel from 'components/AnalyticsPanel';
import { Tab } from '@headlessui/react';
import SearchIcon from '@mui/icons-material/Search';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddchartIcon from '@mui/icons-material/Addchart';
import HomeIcon from '@mui/icons-material/Home';
import styles from '../styles/pages/Dashboard.module.css';

import { useOutletContext } from 'react-router-dom';
import VisualTab from 'components/AnalyticsPanel/VisualTab';
import ReportingTab from 'components/AnalyticsPanel/ReportingTab';
import SkillClusters from 'components/AnalyticsPanel/SkillClustersTab';
import SkillClustersTab from 'components/AnalyticsPanel/SkillClustersTab';
import TickerBar from 'components/AnalyticsPanel/TickerBar';
import SearchOptionsBar from 'components/SearchPanel/SearchOptionsBar';
import { Fragment } from 'react';
import { Nothing, useGetAreaOptions } from 'Utils';
import { useRecoilState } from 'recoil';
import SiteProfilesTab from 'components/AnalyticsPanel/SiteProfilesTab';
import ResultTab from 'components/SearchPanel/ResultTab';
import { recoilSelectedSearchTab } from 'components/SearchPanel/atoms';
import MapTab from 'components/SearchPanel/MapTab';

const Dashboard = ({ platformName }: { platformName: String }) => {
  //

  // Tab structure

  const tabs = [
    // {
    //   name: 'Home',
    //   icon: HomeIcon,
    //   header: Nothing,
    //   subTabs: [],
    //   footer: Nothing,
    // },
    {
      name: 'Analytics',
      icon: BarChartIcon,
      header: Nothing,
      subTabs: [
        { name: 'Ecosystem', panel: <VisualTab /> },
        { name: 'Clustering', panel: <SkillClustersTab /> },
        { name: 'Site Profiles', panel: <SiteProfilesTab /> },
      ],
      footer: Nothing,
    },
    {
      name: 'Search',
      icon: SearchIcon,
      header: Nothing,
      subTabs: [
        { name: 'Results', panel: <ResultTab /> },
        { name: 'Map', panel: <MapTab /> },
      ],
      selectedSubTabState: useRecoilState(recoilSelectedSearchTab),
      footer: Nothing,
    },
    {
      name: 'Reporting',
      icon: AddchartIcon,
      header: Nothing,
      subTabs: [
        { name: 'Reporting', panel: <ReportingTab /> },
      ],
      footer: Nothing,
    },
  ];

  return (
    <>
      {/* // <div className="max-h-full h-full"> */}
      <Tab.Group>
        <div className="toolPanels w-full h-full flex flex-row">
          {/* vertical navbar */}
          <Tab.List
            className="dashboard-nav h-full w-fit flex flex-col 
          py-5 gap-0 
          text-lg font-medium bg-custom-white
          border-r border-neutral-60
          z-10"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className="flex flex-row items-center text-left px-5 py-2 gap-2
                          focus:outline-none 
                          text-neutral-30
                          hover:bg-neutral-color-10
                          hover:text-black
                          ui-selected:bg-neutral-color-20
                          ui-selected:text-brand"
              >
                <tab.icon fontSize="inherit" />
                <span className="tabLabel">{tab.name}</span>
              </Tab>
            ))}
          </Tab.List>

          {/* Dashboard Panel */}
          <Tab.Panels className="dashboard-panel h-full flex-grow flex flex-col bg-neutral-color-20">
            {tabs.map((tab) => (
              <Tab.Panel
                key={tab.name}
                className="h-full flex-grow flex flex-col max-h-full "
              >
                <Tab.Group
                  selectedIndex={tab.selectedSubTabState?.[0]}
                  onChange={tab.selectedSubTabState?.[1]}
                >
                  <Tab.List
                    className="w-full h-11 flex flex-row 
                  bg-neutral-color-10 text-custom-black text-lg font-medium
                 "
                  >
                    {tab.subTabs.map((subtab) => (
                      <Tab
                        key={subtab.name}
                        className="px-11 border-b-brand
                        focus:outline-custom-black
                        hover:bg-neutral-color-20
                        ui-selected:bg-neutral-color-20  ui-selected:border-b-2
                        ui-not-selected:border-b-0 "
                      >
                        {subtab.name}
                      </Tab>
                    ))}
                  </Tab.List>
                  <div className="flex flex-col flex-grow ">
                    <tab.header />
                    <Tab.Panels className="flex flex-grow">
                      {tab.subTabs.map((subtab) => (
                        <Tab.Panel
                          key={subtab.name}
                          className="relative w-full h-full"
                        >
                          {subtab.panel}
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                    <tab.footer />
                  </div>
                </Tab.Group>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
      </Tab.Group>
      {/* </div> */}
    </>
  );
};

export default Dashboard;
