import { geographicArea } from 'Utils';
import { atom } from 'recoil';

export const recoilTopic = atom({
  key: 'topic',
  default: '',
});

export interface preLoadedInput {
  requirement: string;
  textsugg: string;
}

//requirement statement atom
export const recoilRequirement = atom({
  key: 'requirement',
  default: undefined as preLoadedInput | undefined,
});

export const adTypeOptions = ['Courses', 'Jobs'];
//adType
export const recoilAdType = atom({
  key: 'adType',
  default: 'Courses' as 'Courses' | 'Jobs',
});
type timePeriod = { label: string; maxDays: number };
export const timePeriodOptions = [
  { label: 'All time', maxDays: 1000000 },
  { label: 'Last 3 years', maxDays: 365 * 3 },
  { label: 'Last 12 months', maxDays: 365 },
  // { label: 'Last 30 days', maxDays: 30 },
];
export const recoilTimePeriod = atom({
  key: 'timePeriod',
  default: timePeriodOptions[0] as timePeriod,
});

export const recoilRestrictAdToArea = atom({
  key: 'restrictAdToArea',
  default: null as geographicArea, //'', //areas[0],
});

//provider
export const recoilProvider = atom({
  key: 'provider',
  default: '',
});
// export type Location = { latitude: number; longitude: number };

export const recoilSelectedSearchTab = atom({
  key: 'selectedSearchTab',
  default: 0, //0: Results, 1: Map
});

export const recoilSearchText = atom({
  key: 'searchText',
  default: undefined as string | undefined,
});
type postMapData = {
  title: string;
  score: number;
  subtitle: string;
  description: string;
  url: string | undefined;
  coord: number[];
};
export const recoilPostsToDisplayOnMap = atom({
  key: 'posts for map',
  default: [] as postMapData[],
});

export const recoilSelectedPost = atom({
  key: 'selected post',
  default: '' as string,
});
