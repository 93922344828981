import { ReactNode, useState } from 'react';
import AnalyticFiltersPanel, { useFilterValues } from './VisualFiltersPanel';
import { Listbox, Switch } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import AreaComparison from './AreaComparison';
import EvolutionOverTime from './EvolutionOverTime';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import MapComponent from './AreaComparison/components/Map';
// import fullwidthSvgIcon from '../../../assets/icons/fullwidth.svg';
// import columnsSvgIcon from '../../../assets/icons/columns.svg';
import TickerBar from '../TickerBar';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { toast } from 'react-hot-toast';
import {
  useGetAreasAndChildrenQuery,
  useGetCareerQuery,
} from 'generated/graphql';
import { range } from 'data-forge';
// import { ReactSVG } from 'react-svg';

const useEcosystemData = () => {
  const filters = useFilterValues();
  const yearRange = [
    0,
    ...range(
      filters.fromYearToYear[0],
      filters.fromYearToYear[1] - filters.fromYearToYear[0] + 1
    ).toArray(),
  ];
  const restrictToArea_children = useGetAreasAndChildrenQuery({
    variables: {
      parent_id: filters.restrictToArea?.id,
      parent_area_type: filters.restrictToArea?.area_type,
      child_area_type: filters.comparedGeography?.value,
    },
  });
  const restrictToAreaChildrenList =
    [
      ...(restrictToArea_children.data?.parent || []),
      ...(restrictToArea_children.data?.children || []),
      ...(restrictToArea_children.data?.grandchildren || []),
      ...(restrictToArea_children.data?.ggrandchildren || []),
    ].map((a) => a.id) || [];
  const fetchData = useGetCareerQuery({
    variables: {
      terms: filters.terms,
      years: yearRange,
      category: filters.category.value,
      source: filters.industry,
      measure: filters.measure.value,
      area_unit: filters.comparedGeography?.value || 'nation',
      area_restriction: filters.restrictToArea?.id || '',
      area_restriction_unit: filters.restrictToArea?.area_type,
      area_restriction_children: restrictToAreaChildrenList,
    },
  });
  return fetchData;
};
export const useFetchCareerData = () => {
  const filters = useFilterValues();
  const { data, loading } = useEcosystemData();

  return {
    loading: loading,
    global:
      filters.restrictToArea !== null && filters.restrictToArea !== undefined
        ? data?.global_restricted || []
        : data?.global_uk || [],
    geocompared:
      filters.restrictToArea !== null && filters.restrictToArea !== undefined
        ? data?.geocompared_restricted || []
        : data?.geocompared || [],
  };
};
function VisualTab() {
  // const [fullWidthPanel] = useRecoilState(recoilFullWidthPanel);
  const filters = useFilterValues();
  const [fullWidth, setFullWidth] = useState(false);

  const descriptionLocation = filters.restrictToArea?.name || 'UK';
  const descriptionYears =
    filters.fromYearToYear.indexOf(0) === -1
      ? 'from ' + filters.fromYearToYear[0] + ' to ' + filters.fromYearToYear[1]
      : '';

  let [mapRef, setMapRef]: any = useState(undefined);
  let [viewOptionsWithDataEvolution, setViewOptionsWithDataEvolution]: any =
    useState(undefined);
  let [viewOptionsWithDataArea, setViewOptionsWithDataArea]: any =
    useState(undefined);
  //return <></>; //DEBUG

  // console.log('tab', filters.analyticTab.index);
  const debugLayout = (
    <>
      <div className="org-main h-full flex-grow flex flex-col bg-red-100">
        <div className="filters w-full h-20 bg-blue-200 "></div>
        <div className="ticker w-full h-16 bg-pink-200"></div>
        <div className="visual w-full flex-grow relative overflow-hidden bg-green-50">
          <div className="absolute w-full h-full overflow-auto bg-orange-400 p-5">
            <div className="h-screen w-screen bg-purple-300"></div>
          </div>
        </div>
      </div>
    </>
  );
  const debugLayout2 = (
    <>
      <div className="org-main absolute w-full h-full flex flex-col bg-red-100 overflow-y-auto">
        <div className="sticky-cont h-full w-full ">
          <div className="top w-full flex flex-col sticky top-0 bg-blue-200">
            <div className="filters w-full flex-grow bg-green-200">
              <div className="h-20"></div>
            </div>

            <div className="ticker w-full h-20 bg-pink-200"></div>
          </div>
          <div className="visual h-screen w-full bg-green-50">
            {/* <div className="absolute w-full h-full bg-orange-400 p-5"> */}
            <div className="h-screen w-full bg-orange-300"></div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
  const comparisonByArea = //<>barchart</>; //debug
    (
      <ViewPanel
        title="Comparison by area"
        description={`Compared by ${filters.comparedGeography?.name} ${descriptionYears}`}
      >
        <div
          className="cursor-pointer pb-5 text-blue-500"
          onClick={() => {
            console.log(viewOptionsWithDataArea);
            // Here we can save the map to the ARS as a blob
            let ars = JSON.parse(sessionStorage?.getItem('ars') || '[]');
            ars.push({ savedAt: new Date(), ...viewOptionsWithDataArea });
            sessionStorage.setItem('ars', JSON.stringify(ars));
            toast.success('Saved to the Reporting Tab');
          }}
        >
          Save <BookmarkAddIcon />
        </div>
        <AreaComparison setViewOptionsWithData={setViewOptionsWithDataArea} />
      </ViewPanel>
    );
  const map = (
    <ViewPanel
      title="Comparison by area - Map view"
      addClassName={fullWidth ? 'w-full h-screen' : 'w-1/2 h-full'}
      description={`Compared by ${filters.comparedGeography?.name} ${descriptionYears}`}
    >
      <div
        className="cursor-pointer pb-5 text-blue-500"
        onClick={() => {
          console.log(mapRef?.getMap()?.getCanvas()?.toDataURL());
          // Here we can save the map to the ARS as a blob
          let ars = JSON.parse(sessionStorage?.getItem('ars') || '[]');
          ars.push({
            name: 'Map',
            data: mapRef?.getMap()?.getCanvas()?.toDataURL('image/jpeg', 0.2),
            savedAt: new Date(),
          });
          sessionStorage.setItem('ars', JSON.stringify(ars));
          toast.success('Saved to the Reporting Tab');
        }}
      >
        Save <BookmarkAddIcon />
      </div>
      <MapComponent mapRef={mapRef} setMapRef={setMapRef} />
    </ViewPanel>
  );
  const content = (
    <div className="absolute w-full h-full overflow-y-auto">
      <div className="relative w-full h-full flex flex-col">
        {/* the sticky header */}
        <div className="tab-header w-full sticky top-0 flex flex-col z-20">
          <TickerBar />
          <AnalyticFiltersPanel />
        </div>

        {/* panels */}
        <div
          className={`relative w-full flex gap-5 p-5 bg-neutral-90 ${
            fullWidth ? 'flex-col ' : 'flex-row ' // no gap in full width mode
          }`}
        >
          {/* toggle fullwidth mode */}
          <div className="fixed bottom-0 right-0 w-fit text-right p-5 z-30 bg-green">
            <Switch
              className="rounded-md w-20 h-10 p-1 bg-red-800 overflow-hidden relative"
              checked={fullWidth}
              onChange={setFullWidth}
            >
              <div className="absolute w-full h-full top-0 right-0 p-2 gap-4 flex flex-row bg-custom-white"></div>
              <div className="absolute w-full h-8 left-1 top-1 text-left">
                <span
                  className={`${
                    fullWidth ? 'translate-x-9' : 'translate-x-0'
                  } inline-block h-8 w-8 transform rounded-md bg-brand transition`}
                />
              </div>
              <span className="sr-only">Full width mode</span>
            </Switch>
          </div>
          {/* Map */}
          {map}

          {/* charts */}
          <div
            className={` ${
              fullWidth ? 'w-full' : 'w-1/2'
            } flex flex-col flex-grow gap-5 `}
          >
            {/* enable evolution over timeonly for employer demand */}
            {filters.analyticTab.index === 0 ? (
              <ViewPanel
                title="Evolution over time"
                description={descriptionLocation + ' ' + descriptionYears}
              >
                <div
                  className="cursor-pointer pb-5 text-blue-500"
                  onClick={() => {
                    console.log('ars', viewOptionsWithDataEvolution);
                    // Here we can save the map to the ARS as a blob
                    let ars = JSON.parse(
                      sessionStorage?.getItem('ars') || '[]'
                    );
                    ars.push({
                      savedAt: new Date(),
                      ...viewOptionsWithDataEvolution,
                    });
                    sessionStorage.setItem('ars', JSON.stringify(ars));
                    // toast.success(JSON.stringify(ars));
                    toast.success('Saved to the Reporting Tab');
                  }}
                >
                  Save <BookmarkAddIcon />
                </div>
                <EvolutionOverTime
                  setViewOptionsWithData={setViewOptionsWithDataEvolution}
                />
              </ViewPanel>
            ) : (
              <></>
            )}
            {comparisonByArea}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
  return content;
}

function ViewPanel({
  children,
  title = 'Untitled',
  description = '',
  addClassName = 'w-full',
  ...props
}: {
  children?: ReactNode;
  addClassName?: string;
  title?: string;
  description?: string;
}) {
  // return <></>; //DEBUG
  return (
    <div
      className={`bg-white relative shadow rounded-lg transition-all duration-500 p-5 ${
        addClassName + ' '
      }`}
    >
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-col">
          <h2 className="font-bold text-lg">{title}</h2>
          <span>{description}</span>
        </div>
        {children}
      </div>
    </div>
  );
}

export default VisualTab;

export type viewOption = {
  name: string;
  icon: any; // () => ReactJSXElement;
  label: string;
  panel: any; // ReactJSXElement;
};

// for the view as
export function ViewAsListBox({
  selectedView,
  viewOptions,
  setSelectedView,
}: {
  selectedView: viewOption;
  viewOptions: viewOption[];
  setSelectedView: (view: viewOption) => void;
}) {
  return (
    <div className="flex w-44 absolute right-5 top-5">
      <Listbox value={selectedView} onChange={setSelectedView}>
        <Listbox.Button
          className={
            (viewOptions.length > 1 ? 'cursor-pointer ' : 'cursor-default ') +
            'relative w-full rounded-lg bg-white py-2 pl-3 pr-10 text-left ring-1 ring-gray-200 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
          }
        >
          <span className="block truncate">
            <selectedView.icon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className="ml-2">{selectedView.label}</span>
          </span>
          {viewOptions.length > 1 ? (
            <span className="pointer-events-none absolute inset-y-0 right-auto flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          ) : (
            <></>
          )}
        </Listbox.Button>
        {viewOptions.length > 1 ? (
          <Listbox.Options className="absolute mt-10 max-h-60 w-full overflow-visible cursor-pointer z-50 rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none ">
            {viewOptions.map((viewOption) => (
              <Listbox.Option
                key={viewOption.name}
                value={viewOption}
                className="pl-3 ui-active:bg-gray-100 ui-active:opacity-100 opacity-80 cursor-pointer"
              >
                <div className="flex flex-row">
                  <viewOption.icon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2">{viewOption.label}</span>
                </div>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        ) : (
          <></>
        )}
      </Listbox>
    </div>
  );
}
