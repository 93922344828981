import styles from '../styles/components/SignIn.module.css';

import { FormEvent, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Input from './Input';
import { useSignInEmailPassword } from '@nhost/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { AppTitle } from 'Utils';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
  } = useSignInEmailPassword();
  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    signInEmailPassword(email, password);
  };

  if (isSuccess) {
    return <Navigate to="/" replace />;
  }
  const disableForm = isLoading || needsEmailVerification;
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {/* <div className={styles['logo-wrapper']}>
          <img src={process.env.PUBLIC_URL + 'logo.svg'} alt="logo" />
        </div> */}
        <AppTitle />
        <form onSubmit={handleOnSubmit} className={styles.form}>
          <Input
            type="email"
            label="Email address"
            placeholder="Email address"
            value={email}
            onChange={(e: FormEvent<HTMLFormElement>) =>
              setEmail((e.target as HTMLTextAreaElement).value)
            }
            required
            disabled={disableForm}
          />
          <Input
            type="password"
            label="Password"
            placeholder="Password"
            value={password}
            onChange={(e: FormEvent<HTMLFormElement>) =>
              setPassword((e.target as HTMLTextAreaElement).value)
            }
            required
            disabled={disableForm}
          />

          <button
            type="submit"
            className={styles.button}
            disabled={disableForm}
          >
            {isLoading ? <ClipLoader /> : 'Sign in'}
          </button>
          {isError ? (
            <p className={styles['error-text']}>{error?.message}</p>
          ) : null}
        </form>
      </div>

      <p className={styles.text}>
        No account yet?{' '}
        <Link to="/sign-up" className={styles.link}>
          Sign up
        </Link>
      </p>
    </div>
  );
};

export default SignIn;
