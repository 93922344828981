import {
  Bar,
  BarChart,
  BarSeries,
  Gridline,
  GridlineSeries,
  LinearXAxis,
  LinearXAxisTickLabel,
  LinearXAxisTickLine,
  LinearXAxisTickSeries,
  LinearYAxis,
  LinearYAxisTickLabel,
  LinearYAxisTickLine,
  LinearYAxisTickSeries,
} from 'reaviz';
import { dataType } from '..';
import { OneColorScheme, gradientMonoColorScheme } from 'colourSchemes';
function BarChartPanel({ inputData }: { inputData: dataType }) {
  let min = 0;
  let max = 0;
  inputData.map((value) => {
    if (value.data > max) {
      max = value.data;
    } else if (value.data < min) {
      min = value.data;
    }
  });
  return (
    <>
      <div className="px-10 flex flex-row gap-20 w-full content-center">
        <BarChart
          // width={500}
          height={100 + inputData.length * 25}
          data={inputData}
          series={
            <BarSeries layout="horizontal" colorScheme={OneColorScheme} />
          }
          // centerX={true}
          xAxis={
            <LinearYAxis
              orientation="horizontal"
              domain={[min, max]}
              position="end"
              type="value"
              tickSeries={
                <LinearXAxisTickSeries
                  line={<LinearXAxisTickLine position="center" />}
                  label={<LinearXAxisTickLabel padding={10} />}
                />
              }
            />
          }
          gridlines={<GridlineSeries line={<Gridline direction="x" />} />}
          yAxis={
            <LinearYAxis
              orientation="vertical"
              type="category"
              axisLine={null}
              tickSeries={
                <LinearYAxisTickSeries
                  line={null}
                  // line={<LinearYAxisTickLine position="center" />}
                  label={<LinearYAxisTickLabel padding={10} />}
                  tickSize={0}
                />
              }
            />
          }
        />
      </div>
    </>
  );
}

export default BarChartPanel;
