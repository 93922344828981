import { recoilSearchText, recoilSelectedSearchTab } from '../atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import IconButton from '@mui/material/IconButton';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { useEffect, useState } from 'react';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CloseIcon from '@mui/icons-material/Close';

type AdSentences = {
    Sentences: string[] | null;
    SentenceScores: number[][] | null;
} 

function CompareFeature({ post}: { post: AdSentences}){
    const searchTerms = useRecoilValue(recoilSearchText);
    const [comparing, setComparing] = useState(false);

      //scores2D is an array of array of scores, it's post.SentenceScores flipped. score2D[i]
  const scores2DgroupedByDescSentences = post.SentenceScores?.[0].map(
    (_, colIndex) => post.SentenceScores?.map((row) => row[colIndex])
  );
  const descSentencesScoreList = scores2DgroupedByDescSentences?.map(
    (score, idx) => ({
      score: (score || []).reduce((a, b) => a + b, 0) / (score?.length || 1), //score is the average scores for this sentence
      index: idx,
    })
  );
  const inputSentencesScoreList = post.SentenceScores?.map((score, idx) => ({
    score: (score || []).reduce((a, b) => a + b, 0) / (score?.length || 1), //score is the average scores for this sentence
    index: idx,
    sentence: '', //will find later
  }));

  descSentencesScoreList?.sort((a, b) => b.score - a.score);
  inputSentencesScoreList?.sort((a, b) => b.score - a.score);

  console.log(descSentencesScoreList);
  const maxSentenceCount = 3;
  const unmatchedList = inputSentencesScoreList?.slice(
    inputSentencesScoreList?.length - maxSentenceCount,
    inputSentencesScoreList?.length
  );

  const matchedScoreList = descSentencesScoreList
    ?.slice(0, maxSentenceCount)
    .filter((score) => score?.score >= 0.4);

  // split by dot and eventually take the next whitespace away
  const inputSentenceList = splitUpText(searchTerms||'');

  unmatchedList?.forEach((row) => {
    row.sentence = inputSentenceList?.[row.index] || '';
  });

    // const unmatchedSentenceList = searchTerms
  //     ?.split('. ') //array of sentencees, now let's find the ones with index found in unmatchedScoreList
  //     ?.filter((value, idx) => unmatchedScoreList?.map((score) => score.index)?.includes(idx));


  return (
    <div
          className={
            (comparing ? 'z-20 ' : 'z-0  ') +
            'flex-col gap-10 p-5 items-center bg-custom-white rounded-l-lg w-fit '
          }
        >
      <div className= {(comparing ? 'pl-5 ' : 'pl-0  ')+"compare-header flex flex-row gap-5 justify-between "}>
        <div
          className="compare-heading flex flex-row gap-1 justify-start text-brand cursor-pointer "
          onClick={() => setComparing(!comparing)}
        >
          <CompareArrowsIcon />
          <h3 className="text-base font-bold">Compare</h3>
        </div>
        <div className={comparing ? 'flex cursor-pointer ' : 'hidden'}>
          <IconButton onClick={() => setComparing(!comparing)}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={comparing ? 'flex flex-col gap-5 pt-5' : 'hidden'}>
        {/* body */}
        {matchedScoreList?.map((score, idx) => (
          <ComparisonSentence
            key={idx}
            text={post.Sentences?.[score.index] || ''}
            score={score.score}
          />
        ))}
        <div className="h-px bg-custom-black mx-10"></div>
        {unmatchedList?.map((row, idx) => (
          <ComparisonSentence
            key={idx}
            text={row.sentence || ''}
            score={row.score}
          />
        ))}
        {/* {unmatchedScoreList?.map((score, idx) => (
          <ComparisonSentence
            key={idx}
            text={post.Sentences?.[score.index] || ''}
            score={score.score}
          />
        ))} */}
      </div>
    </div>
  );
}
function splitUpText(text: string): string[] {
  const pattern = /[^\w\s()'"",;.:?!]/;
  let sentences = text.split(/[.|\n\n]/); // Split by . due to our input using . for listing Key Responsibilities
  sentences = sentences.map((s) => s.trim()).filter((s) => s !== ''); // Remove empty sentences
  sentences = sentences.map((s) => s.replace(pattern, '')); // Remove other symbols
  return sentences;
}

function ComparisonSentence({ text, score }: { text: string; score: number }) {
    text = text.replaceAll('?', '');
    text = text.replace('\r', '\n');
    text = text.replace(/(\n){3,}/g, '\n\n');
    text = text.replace(/ +(?= )/g, '');
    text = text.replace(/(\n )/g, '\n');
    const [userReaction, setUserReaction] = useState(0); // 0 = neutral, 1 = like, -1 = dislike
    // useEffect(() => {
    //   if (userReaction != 0)
    //     console.log(
    //       'updating',
    //       text.substring(0, 50),
    //       userReaction > 0 ? '+' : ' ',
    //       userReaction
    //     );
    // }, [userReaction]);
    const updateGlobalUserReaction = (variation: number) => {
      console.log(
        'updating',
        text.substring(0, 50),
        variation > 0 ? '+' : ' ',
        variation
      );
      return;
    };
    return (
      <div className="flex flex-row justify-between gap-5 relative">
        {/* black circle */}
        <div className=" absolute top-2 w-1 h-1 rounded-full bg-brand"></div>
        <div className="text-neutral-20 max-w-2xl pl-5 text-left flex-grow text-sm">
          {text}
        </div>
        <div className="flex flex-col items-center">
          {score !== 0 && (
            <div className="flex flex-row text-sm">
              Score :{Math.round(score * 100)}%
            </div>
          )}
          <div className="flex flex-row">
            <IconButton
              onClick={() => {
                if (userReaction === 1) {
                  updateGlobalUserReaction(-1); // let's decrease back
                  setUserReaction(0);
                } //unselect
                else if (userReaction === -1) {
                  updateGlobalUserReaction(2); // let's increase twice
                  setUserReaction(1);
                } else {
                  updateGlobalUserReaction(1); // let's increase
                  setUserReaction(1);
                } //like
              }}
              color={userReaction === 1 ? 'success' : 'default'}
            >
              <ThumbUpOffAltIcon />
            </IconButton>
            <IconButton>
              <ThumbDownOffAltIcon
                onClick={() => {
                  if (userReaction === -1) {
                    // if it was already disliked
                    updateGlobalUserReaction(1); // let's increase back
                    setUserReaction(0); //unselect
                  } else if (userReaction === 1) {
                    updateGlobalUserReaction(-2); // let's decrease twice
                    setUserReaction(-1);
                  } else {
                    updateGlobalUserReaction(-1); // let's decrease
                    setUserReaction(-1);
                  }
                }}
                color={userReaction === -1 ? 'error' : 'action'}
              />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
  

export default CompareFeature;