import { Layer } from '@urbica/react-map-gl';
import { useFilterValues } from 'components/AnalyticsPanel/VisualTab/VisualFiltersPanel';
import { Expression } from 'mapbox-gl';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAreaCompData } from '../..';
import { gradientMonoColorScheme } from 'colourSchemes';
import { recoilSelectedEmployers } from 'components/AnalyticsPanel/SiteProfilesTab/atoms';
import { recoilCursor } from './atoms';
import { recoilSelectedPost } from 'components/SearchPanel/atoms';

export default function Layers() {
  const [selectedEmployers, setSelectedEmployers] = useRecoilState(
    recoilSelectedEmployers
  );
  const [selectedPost, setSelectedPost] = useRecoilState(recoilSelectedPost);
  const [cursor, setCursor] = useRecoilState(recoilCursor);
  const filters = useFilterValues();
  const areaComp = useAreaCompData();
  const areaCompWithEmpty = [{ key: '', data: 0 }, ...areaComp.data]; //add empty value for non queried areas
  const colorScheme = ['#fff', ...gradientMonoColorScheme]; //add white for empty values
  let min = 0;
  let max = 0;
  areaCompWithEmpty.map((value) => {
    if (value.data > max) {
      max = value.data;
    } else if (value.data < min) {
      min = value.data;
    }
  });

  const view = 'absolute'; //don't know what that is yet

  // const [colors, setColors] = useState<Expression | string>('grey');
  // Effects to change area's colour depending on it's count/prop value
  // useEffect(() => {
  const colors = [
    'case',
    ['to-boolean', ['feature-state', 'value']],
    [
      'interpolate',
      ['linear'],
      ['feature-state', 'value'],

      ...colorScheme
        .map((color: any, index: any, a: any) => {
          const value =
            (areaCompWithEmpty[
              Math.floor((index / a.length) * (areaCompWithEmpty.length || 1))
            ].data || 0) + index;
          // console.log('interpolation, ', color, index, '->', value);
          // console.log('bordel 2', index / a.length, color);
          return [value, color];
        })

        .flat(),
    ],
    'white',
  ] as Expression;
  // setColors(colorIntLads);
  // }, [areaComp.data]); //there was count range but no idea how it's affecting it, and view, but i won't care i think
  // return <></>;
  return (
    <>
      <>
        <Layer
          id="lads-fill"
          type="fill"
          source="admin"
          source-layer="LADs_4326"
          paint={{ 'fill-color': colors, 'fill-opacity': 0.7 }}
          layout={{
            visibility:
              filters.comparedGeography?.value === 'lad' ? 'visible' : 'none',
          }}
          before="admin-1-boundary-bg"
        />
      </>
      <>
        <Layer
          id="counties-fill"
          type="fill"
          source="admin"
          source-layer="Counties_4326"
          paint={{ 'fill-color': colors, 'fill-opacity': 0.7 }}
          layout={{
            visibility:
              filters.comparedGeography?.value === 'canoncounty'
                ? 'visible'
                : 'none',
          }}
          before="admin-1-boundary-bg"
        />
      </>

      <>
        <Layer
          id="regions-fill"
          type="fill"
          source="admin"
          source-layer="Regions_4326"
          paint={{ 'fill-color': colors, 'fill-opacity': 0.7 }}
          layout={{
            visibility:
              filters.comparedGeography?.value === 'region'
                ? 'visible'
                : 'none',
          }}
          before="admin-1-boundary-bg"
        />
      </>
      <>
        <Layer
          id="nations-fill"
          type="fill"
          source="admin"
          source-layer="Nations_4326"
          onClick={(e: any) => console.log('features', e.features)}
          // paint={{ 'fill-color': 'blue', 'fill-opacity': 0.8 }}
          paint={{ 'fill-color': colors, 'fill-opacity': 0.7 }}
          layout={{
            visibility:
              filters.comparedGeography?.value === 'nation'
                ? 'visible'
                : 'none',
          }}
          before="admin-1-boundary-bg"
        />
      </>
      <>
        <Layer
          id="countsandprops"
          type="symbol"
          source="values"
          paint={{
            'text-color': 'black',
          }}
          layout={{
            'text-field': ['get', 'value'],
            'text-font': ['Montserrat SemiBold'],
            'text-size': 18,
          }}
        />
      </>
      <>
        <Layer
          id="points"
          type="symbol"
          source="points"
          paint={{
            'text-color': 'black',
          }}
          onHover={(e: any) => {
            setCursor('pointer');
          }}
          onLeave={(e: any) => {
            setCursor('default');
          }}
          onClick={(e: any) => {
            console.log('click');
            // e.features[0].properties.employers;
            let str = e.features[0].properties.employers as string;
            //delete first '["' and last '"]'
            str = str.replace('["', '').replace('"]', '');
            const arr = str.split('","');
            setSelectedEmployers(arr); // !! this gives me a string
          }}
          layout={{
            'icon-image': 'custom-marker',
            'text-field': ['get', 'title'],
            'text-font': ['Montserrat SemiBold'],
            'text-size': 18,
            'text-anchor': 'top',
            'text-offset': [0, 1.25],
          }}
        />
      </>
      <>
        <Layer
          id="search"
          type="symbol"
          source="search"
          paint={{
            'text-color': 'black',
          }}
          onHover={(e: any) => {
            setCursor('pointer');
          }}
          onLeave={(e: any) => {
            setCursor('default');
          }}
          onClick={(e: any) => {
            console.log(e.features[0].properties.title);
            setSelectedPost(e.features[0].properties.title);
          }}
          layout={{
            'icon-image': 'custom-marker',
            'text-field': ['get', 'label'],
            'text-font': ['Montserrat SemiBold'],
            'text-size': 11,
            'text-anchor': 'top',
            'text-offset': [0, -1],
          }}
        />
      </>
      <></>
    </>
  );
}
