import { useGetAreasLikeQuery } from 'generated/graphql';
import { AppVersion } from 'version_variables/version_variables';

export const AppName = 'Foresighter';

export function AppTitle() {
  return (
    <h1 className="title flex flex-col text-center p-5 pb-20 text-brand gap-1">
      <span className="text-4xl font-semibold">{AppName} </span>
      <span className="text-[14px] font-light">{AppVersion}</span>
    </h1>
  );
}

export const defaultSkillClustersTerms = [
  'Design and Construction',
  'Wastewater Treatment',
];

export type geographicArea = {
  id: string;
  name: string;
  area_type: areaTypeType;
} | null;
type areaTypeType = 'nation' | 'canoncounty' | 'region' | 'lad';

const areaTypeOptions = [
  'nation',
  'canoncounty',
  'region',
  'lad',
] as areaTypeType[];
export const areaTypeLabel = (areatype: areaTypeType) => {
  switch (areatype) {
    case 'nation':
      return 'Nation';
    case 'canoncounty':
      return 'County';
    case 'region':
      return 'Region';
    case 'lad':
    default:
      return 'LAD';
  }
};
export function capitalizeFirstLetter(string: String) {
  string = string.toLowerCase();
  return string.charAt(1).toUpperCase() + string.slice(2);
}
export function JobDateToYear(jobDate: string) {
  return parseInt(jobDate.split('-')[0]);
}

export function shortenString(str: string, maxlength = 15) {
  return str.length > maxlength ? str.substring(0, maxlength - 3) + '...' : str;
}

export function interpolate(x: number, domain: number[], range: number[]) {
  //domain is the range of the input values
  //range is the range of the output values
  return (
    range[0] +
    ((x - domain[0]) * (range[1] - range[0])) / (domain[1] - domain[0])
  );
}
// interpolate exponentially
export function interpolateExp(x: number, domain: number[], range: number[]) {
  //domain is the range of the input values
  //range is the range of the output values
  return (
    range[0] *
    Math.pow(range[1] / range[0], (x - domain[0]) / (domain[1] - domain[0]))
  );
}
// interpolate parabolically
export function interpolateQuadratic(
  x: number,
  domain: number[],
  range: number[]
) {
  //domain is the range of the input values
  //range is the range of the output values
  return (
    range[0] +
    ((x ** 2 - domain[0] ** 2) * (range[1] - range[0])) /
      (domain[1] ** 2 - domain[0] ** 2)
  );
}

export const Nothing = () => <></>;

export const useGetAreaOptions = (searchArea: string) => {
  const query = useGetAreasLikeQuery({
    variables: { like: '%' + searchArea + '%' },
  });
  // we will have to only display what is smaller than the restricted unit
  // const queriedAreas = query.data?.areas.map((v) => ({
  //   id: v.id || '',
  //   name: v.name || '',
  //   area_type: v.area_type || '',
  // })) as geographicArea[];
  // console.log('areas: ', query.data?.areas);
  return {
    data: query.data?.areas as geographicArea[],
    loading: query.loading,
  };
};

// export const useGetAreaOptionsNoLAD = () => {
//   const query = useGetAreasQuery();

//   let queriedAreas = query.data?.nations.map((v) => ({
//     areaCode: v.id || '',
//     name: v.name || '',
//     unit: 'UK Nation',
//   })) as geographicArea[];

//   query.data?.regions.map((v) =>
//     queriedAreas?.push({
//       id: v.id || '',
//       name: v.name || '',
//       area_type: 'Region',
//     })
//   );

//   query.data?.counties.map((v) =>
//     queriedAreas?.push({
//       id: v.id || '',
//       name: v.name || '',
//       area_type: 'County',
//     })
//   );

//   // query.data?.lads.map((v) =>
//   //   queriedAreas?.push({
//   //     areaCode: v.id || '',
//   //     name: v.name || '',
//   //     unit: 'LAD',
//   //   })
//   // );
//   return { data: queriedAreas, loading: query.loading };
// };
