import styles from '../styles/components/SignUp.module.css';

import { FormEvent, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Input from './Input';
import { useSignUpEmailPassword } from '@nhost/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { AppTitle } from 'Utils';
import { license_terms_url } from 'version_variables/version_variables';
const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [department, setDepartment] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {
    signUpEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
  } = useSignUpEmailPassword();
  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    signUpEmailPassword(email, password, {
      displayName: `${firstName} ${lastName}`.trim(),
      metadata: { firstName, lastName, role, organisation, department },
    });
  };
  if (isSuccess) {
    return <Navigate to="/" replace={true} />;
  }
  const disableForm = isLoading || needsEmailVerification;
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {/* <div className={styles['logo-wrapper']}>
          <img src={process.env.PUBLIC_URL + 'logo.svg'} alt="logo" />
        </div> */}
        <AppTitle />
        {needsEmailVerification ? (
          <p className={styles['verification-text']}>
            To start using the platform, please check your mailbox and confirm
            your email address
          </p>
        ) : (
          <form onSubmit={handleOnSubmit} className={styles.form}>
            <div className={styles['input-group']}>
              <Input
                label="First name"
                placeholder="First name*"
                value={firstName}
                onChange={(e: FormEvent<HTMLFormElement>) =>
                  setFirstName((e.target as HTMLTextAreaElement).value)
                }
                required
                disabled={disableForm}
              />
              <Input
                label="Last name"
                placeholder="Last Name*"
                value={lastName}
                onChange={(e: FormEvent<HTMLFormElement>) =>
                  setLastName((e.target as HTMLTextAreaElement).value)
                }
                required
                disabled={disableForm}
              />
            </div>
            <Input
              type="email"
              label="Email address"
              placeholder="Email address*"
              value={email}
              onChange={(e: FormEvent<HTMLFormElement>) =>
                setEmail((e.target as HTMLTextAreaElement).value)
              }
              required
              disabled={disableForm}
            />
            <Input
              type="password"
              label="Create password"
              placeholder="Create Password*"
              value={password}
              onChange={(e: FormEvent<HTMLFormElement>) =>
                setPassword((e.target as HTMLTextAreaElement).value)
              }
              required
              disabled={disableForm}
            />

            <hr />
            <div className={styles['input-group']}>
              <Input
                label="Role"
                placeholder="Role*"
                value={role}
                onChange={(e: FormEvent<HTMLFormElement>) =>
                  setRole((e.target as HTMLTextAreaElement).value)
                }
                required
                disabled={disableForm}
              />
              <Input
                label="Organisation"
                placeholder="Organisation*"
                value={organisation}
                onChange={(e: FormEvent<HTMLFormElement>) =>
                  setOrganisation((e.target as HTMLTextAreaElement).value)
                }
                disabled={disableForm}
              />
              <Input
                label="Department/Unit"
                placeholder="Department*"
                // className="text-sm"
                value={department}
                onChange={(e: FormEvent<HTMLFormElement>) =>
                  setDepartment((e.target as HTMLTextAreaElement).value)
                }
                required
                disabled={disableForm}
              />
            </div>
            <hr />
            <p
              className={`${styles.text} max-w-xl mx-auto flex flex-col gap-10 items-center`}
            >
              <div className="w-full">
                Your use of the software and services is subject to the{' '}
                <a className={styles.link} href={license_terms_url}>
                  licence terms
                </a>
                . Please read these carefully.
                <br /> <br />
                by clicking on the &quot;Accept &amp; Create Account&quot;
                button below you are agreeing that your use of the Software and
                Services will be on the terms set out in the licence. <br /> for
                the benefit of all users we need everyone using the Software and
                Services to agree to comply with the licence terms. Therefore,
                your access to the Software and Services is conditional upon
                your acceptance of, and compliance with, the licence terms.{' '}
                <br /> we recommend that you print a copy of the terms for
                future reference.
              </div>
              <button
                type="submit"
                className={`${styles.button} max-w-md`}
                disabled={disableForm}
              >
                Accept & Create Account
              </button>
            </p>
            {isError ? (
              <p className={styles['error-text']}>{error?.message}</p>
            ) : null}
          </form>
        )}
      </div>

      <p className={`${styles.text} max-w-xl mx-auto`}>
        Personal data you provide in completing this form will be used in
        accordance with Privacy Notice at the foot of this registration page.
        <br />{' '}
        {/* <Link to="/privacy" className={styles.link}>
          Privacy and Cookies
        </Link> */}
        <a
          className={styles.link}
          href="https://oxdata-privacy.s3.eu-west-2.amazonaws.com/privacy_policy.pdf"
        >
          Privacy and cookies
        </a>
      </p>

      <p className={styles.text}>
        Already have an account?{' '}
        <Link to="/sign-in" className={styles.link}>
          {isLoading ? <ClipLoader /> : 'Sign in'}
        </Link>
      </p>
    </div>
  );
};

export default SignUp;
