import {
  categoryType,
  termGroupType,
} from 'components/AnalyticsPanel/VisualTab/atoms';

// Engineering Construction

// export const industryList = [
//   { key: 'ECITB', value: 'ecitb' },
//   { key: 'Wider ECITB', value: 'wider_ecitb' },
//   { key: 'SIC 41', value: 'sic_41' },
//   { key: 'SIC 42', value: 'sic_42' },
//   { key: 'SIC 43', value: 'sic_43' },
//   { key: 'SIC C', value: 'sic_c' },
//   { key: 'SIC F', value: 'sic_f' },
// ];

// export const license_terms_url =
//   'https://oxdata-privacy.s3.eu-west-2.amazonaws.com/EC_terms.pdf';

// export const categoryOptions = [
//   //change order to change default value
//   { name: 'Job role', value: 'job' },
//   { name: 'Skill', value: 'skill' },
// ] as categoryType[];

// export const defaultTerms = [
//   'Electrician',
//   'Civil Engineer',
//   'Project Manager',
// ]; // 'PipeFitting', 'Welding'//['Electrician', 'Civil Engineer', 'Project Manager']

// export const AppVersion = 'ENGINEERING CONSTRUCTION';
// export const coursesAPIEndpoint = 'https://search.foresighter.ai/courses';
// export const jobsAPIEndpoint = 'https://search.foresighter.ai/lightcast';
// export const termGroupSelect = true as boolean;
// export const termGroupOptions = {
//   job: [{ name: 'All', value: 'job' }] as termGroupType[],
//   skill: [
//     { name: 'All', value: 'skill' },
//     { name: 'Technologies', value: 'tech' },
//     { name: 'Emerging', value: 'emergent' },
//   ] as termGroupType[],
// };
// Automotive:

export const industryList = [
  { key: 'SIC 29', value: 'sic_29' },
  { key: 'SIC 291', value: 'sic_291' },
  { key: 'SIC 293', value: 'sic_293' },
  { key: 'SIC 303', value: 'sic_303' },
  { key: 'SIC C', value: 'sic_c' },
];

export const license_terms_url =
  'https://oxdata-privacy.s3.eu-west-2.amazonaws.com/AM_terms.pdf';

export const categoryOptions = [
  //change order to change default value
  { name: 'Job role', value: 'job' },
  { name: 'Skill', value: 'skill' },
] as categoryType[];

export const defaultTerms = [
  'ADAS Systems Engineer',
  'Battery Specialist',
  'CAD Engineer',
  'Controls and Electronics Engineer',
  'Cyber Security Engineer',
  'Electrical Component Engineer',
  'Electrical Distribution System Engineer',
  'Electronics Designers and Test Engineer',
  'Factory Operative',
  'Lightweight EV Structures Engineer',
  'Metallurgist and Materials Scientist',
  'Robotics Specialist',
  'Software Engineer',
  'Toolmaker',
];
export const AppVersion = 'AUTOMOTIVE';
export const coursesAPIEndpoint =
  'https://search.foresighter.ai/courses-automotive';
export const jobsAPIEndpoint =
  'https://search.foresighter.ai/automotive-search';
export const termGroupSelect = true as boolean;
export const termGroupOptions = {
  job: [
    { name: 'All', value: 'job' },
    { name: 'Emerging', value: 'emergent' },
  ] as termGroupType[],
  skill: [
    { name: 'All', value: 'skill' },
    { name: 'Technologies', value: 'tech' },
    { name: 'Emerging', value: 'emergent' },
  ] as termGroupType[],
};
