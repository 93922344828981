import { useEffect, useState } from 'react';
import MapComponent from '../VisualTab/AreaComparison/components/Map';
import { RadioGroup } from '@headlessui/react';
import BarChartPanel from '../VisualTab/AreaComparison/components/BarChartPanel';
import {
  useGetJobsAndSkillsOnEmployersQuery,
  useGetSitesQuery,
} from 'generated/graphql';
import { Series } from 'data-forge';
import { recoilSelectedEmployers } from './atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { recoilViewport } from '../VisualTab/atoms';
import ClipLoader from 'react-spinners/ClipLoader';

export type Site = { coord: number[]; employers: string[] };

function SiteProfilesTab() {
  const categoryOpt = ['Skills', 'Jobs'];
  const [category, setCategory] = useState(categoryOpt[0]);
  const sitesQuery = useGetSitesQuery();
  const selectedEmployers = useRecoilValue(recoilSelectedEmployers);
  const EmployerQuery = useGetJobsAndSkillsOnEmployersQuery({
    variables: { employers: selectedEmployers },
  });
  type EmployerData = {
    employer: string;
    data: { key: string; areaCode: string; data: number }[];
  };

  const [employersData, setEmployersData] = useState<EmployerData[]>([]);
  // const [viewport, setViewport] = useRecoilState(recoilViewport);
  // setViewport({
  //   latitude: viewport.latitude,
  //   longitude: viewport.longitude,
  //   zoom: 8,
  // });

  const allSites = new Series(sitesQuery.data?.sites || [])
    .groupBy((row) => (row.Longitude, row.Latitude))
    .select((group) => ({
      coord: [
        parseFloat(group.first().Longitude + ''),
        parseFloat(group.first().Latitude + ''),
      ] as number[],
      employers: group.select((row) => row.CanonEmployer).toArray(),
    }))
    .inflate()
    .toArray() as Site[];

  useEffect(() => {
    if (!EmployerQuery.loading) {
      const countsOnEmployers = (
        category === 'Skills'
          ? new Series(EmployerQuery.data?.skills || [])
          : new Series(EmployerQuery.data?.jobs || [])
      )
        .groupBy((row) => row.Employer)
        .select((employerGrp) => ({
          employer: employerGrp.first().Employer || '-',
          data: employerGrp
            .select((row) => ({
              key: row.Label + '',
              areaCode: '',
              data: row.Count,
            }))
            .inflate()
            .toArray(),
        }))
        // .inflate()
        .toArray();
      setEmployersData(countsOnEmployers);
    }
  }, [EmployerQuery.data]);
  // if (EmployerQuery.loading) return <ClipLoader />;

  return (
    // <div className="flex flex-row h-full w-full ">
    <div className="flex flex-col w-full h-full max-h-screen p-5">
      <div className="flex flex-row flex-grow h-full gap-5">
        <MapComponent siteProfileMode={true} sites={allSites} minZoom={5} />
        {/* <div className="h-full w-full rounded-lg">
        </div> */}
        <div className="flex flex-col w-1/2 h-full overflow-auto pb-20 items-center gap-5">
          {selectedEmployers.length === 0 ? (
            <h3 className="text-lg font-semibold text-center py-5">
              {/* put a list box with employer list. default options  */}
              {'Select an employer to visualize "' +
                category.toLowerCase() +
                '" counts.'}
            </h3>
          ) : (
            <></>
          )}
          {/* Filter */}
          <RadioGroup
            value={category}
            onChange={(e) => {
              setCategory(e);
            }}
            className="flex flex-row rounded-full gap-2"
          >
            {categoryOpt.map((c) => (
              <RadioGroup.Option
                key={c}
                value={c}
                className={({ active, checked }) =>
                  `${active ? '' : ''}
                ${
                  checked
                    ? 'bg-brand text-custom-white hover:bg-brand-light'
                    : 'bg-custom-white text-brand hover:bg-transparent '
                }
                   flex text-base cursor-pointer rounded-full px-3 py-1 focus:outline-none border border-brand`
                }
              >
                {({ active, checked }) => (
                  <RadioGroup.Label
                    as="span"
                    className={` inline w-full font-medium text-center align-middle 
                        
                      `}
                  >
                    {c}
                  </RadioGroup.Label>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>

          {EmployerQuery.loading ? (
            <ClipLoader />
          ) : (
            <div className="flex flex-col gap-5 w-full">
              {employersData.map((emp) => (
                <div className="rounded-lg bg-custom-white gap-5 py-5 ">
                  <h4 className="text-base font-medium w-full text-center pb-5">
                    {emp.employer}
                  </h4>
                  <BarChartPanel inputData={emp.data} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default SiteProfilesTab;
