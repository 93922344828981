import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Listbox, RadioGroup, Tab } from '@headlessui/react';

import { ToggleTab } from 'components/Input';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Settings from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  geographicArea as GeographicArea,
  areaTypeLabel,
  shortenString,
  useGetAreaOptions,
} from 'Utils';
import Slider from '@mui/material/Slider';

import {
  recoilRestrictToArea,
  recoilAnalyticTab,
  recoilComparedGeography,
  recoilCategory,
  recoilTerms,
  recoilFromYearToYear,
  AnalyticTabs,
  recoilComparedGeographyAvailOpt,
  comparedGeographyAllOpt,
  GeographicSize,
  recoilIndustry as recoilIndustry,
  recoilFilterInstance,
  recoilMeasure,
  recoilQueryLabels,
  recoilTermGroup,
} from './atoms';
import {
  categoryOptions,
  defaultTerms,
  industryList,
  termGroupOptions,
  termGroupSelect,
} from 'version_variables/version_variables';
import {
  useGetAreasAndChildrenQuery,
  useGetTermsQuery,
} from 'generated/graphql';

// type TermCategory = { name: string; terms: string[] };

//delete duplicates and terms that are already selected (called forbidden terms here)
export function eliminateDuplicates(
  array: string[],
  forbiddenTerms: string[] = []
) {
  let result: string[] = [];
  let isAlreadyThere = false;
  array.forEach((inputTerm) => {
    result.forEach((outputTerm) => {
      if (outputTerm === inputTerm) isAlreadyThere = true;
    });
    forbiddenTerms.forEach((forbiddenTerm) => {
      if (forbiddenTerm === inputTerm) isAlreadyThere = true;
    });
    if (!isAlreadyThere) result.push(inputTerm);
    isAlreadyThere = false;
  });
  return result;
}

export const useFilterValues = () => {
  const category = useRecoilValue(recoilCategory); //selected term category (job, skill, etc)
  const terms = useRecoilValue<string[]>(recoilTerms); //selected terms

  const analyticTab = useRecoilValue(recoilAnalyticTab); // employer demand, Market Value, etc
  const queryLabels = useRecoilValue(recoilQueryLabels); //count, count change, proportion, etc
  const fromYearToYear = useRecoilValue(recoilFromYearToYear); //selected time frame

  const restrictToArea = useRecoilValue(recoilRestrictToArea);
  const comparedGeography = useRecoilValue(recoilComparedGeography);
  const industry = useRecoilValue(recoilIndustry);
  const instance = useRecoilValue(recoilFilterInstance); //just the index of the filter set. is changed every time a filter is changed to trigger useffectcs
  const measure = useRecoilValue(recoilMeasure);
  return {
    category: category,
    terms: terms,
    analyticTab: analyticTab,
    queryLabels: queryLabels,
    fromYearToYear: fromYearToYear,
    restrictToArea: restrictToArea,
    comparedGeography: comparedGeography,
    industry: industry,
    instance: instance,
    measure: measure,
  };
};

function AnalyticFiltersPanel() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>(''); //what is typed in the search bar
  const [searchArea, setSearchArea] = useState<string>(''); //what is typed in the search bar

  const yearDomain = [
    2012,
    new Date().getFullYear(), //this year
  ]; //oldest and most recent year possible,
  // const [termCategory, setTermCategory, terms, setTerms, analyticTab, setAnalyticTab, queryLabels, setQueryLabels, fromYearToYear, setFromYearToYear, restrictToArea, setRestrictToArea, comparedGeography, setComparedGeography] = useFilterStates(); //not working
  // const [jobList, setJobList] = useRecoilState(recoilJobList);
  const [category, setCategory] = useRecoilState(recoilCategory); //selected term category (job, skill, etc)
  const [terms, setTerms] = useRecoilState<string[]>(recoilTerms); //selected terms

  const [analyticTab, setAnalyticTab] = useRecoilState(recoilAnalyticTab); // employer demand, Market Value, etc
  const [measure, setMeasure] = useRecoilState(recoilMeasure); //count, count change, proportion, etc
  const [queryLabels, setQueryLabels] = useRecoilState(recoilQueryLabels); //the measure selected for each tab
  const [fromYearToYear, setFromYearToYear] =
    useRecoilState(recoilFromYearToYear); //selected time frame

  const areaOptions = useGetAreaOptions(searchArea);
  const [restrictToArea, setRestrictToArea] =
    useRecoilState(recoilRestrictToArea);
  const [comparedGeographyAvailOpt, setComparedGeographyAvailOpt] =
    useRecoilState(recoilComparedGeographyAvailOpt);
  const [comparedGeography, setComparedGeography] = useRecoilState(
    recoilComparedGeography
  );
  const [termGroup, setTermGroup] = useRecoilState(recoilTermGroup);
  const [industry, setIndustry] = useRecoilState(recoilIndustry);
  const [instance, setInstance] = useRecoilState(recoilFilterInstance);

  // this will have duplicates
  const autosuggTerms = useGetTermsQuery({
    variables: { like: '%' + searchTerm + '%' },
  });

  useEffect(() => {
    if (restrictToArea != null)
      //restrict the options for compared geography
      switch (restrictToArea.area_type) {
        case 'nation':
          setComparedGeographyAvailOpt(comparedGeographyAllOpt.slice(1));
          break;
        case 'region':
          setComparedGeographyAvailOpt(comparedGeographyAllOpt.slice(2));
          break;
        case 'canoncounty':
          setComparedGeographyAvailOpt(comparedGeographyAllOpt.slice(3));
          break;
        case 'lad':
          setComparedGeographyAvailOpt(comparedGeographyAllOpt.slice(4));
          break;
      }
    else setComparedGeographyAvailOpt(comparedGeographyAllOpt);
  }, [restrictToArea]);
  // useEffect(() => {
  //   //st
  // }, [comparedGeography]);
  //on comparedGeographyAvailOpt change, make sure comparedGeography is in the list
  useEffect(() => {
    if (
      comparedGeographyAvailOpt.indexOf(
        comparedGeography || { name: '', value: 'nation' }
      ) === -1
    )
      setComparedGeography(comparedGeographyAvailOpt[0]);
  }, [comparedGeographyAvailOpt]);
  // console.log('area', restrictToArea);

  useEffect(() => {
    console.log(
      'instance',
      instance + 1,
      '\n-category',
      category.value,
      '\n measure',
      measure.value,
      ' from',
      fromYearToYear[0],
      ' to ',
      fromYearToYear[1],
      '\n-terms:',
      ...terms,
      '\n-restrict area ',
      restrictToArea,
      'compared by ',
      comparedGeography?.value,
      ' \n-comparedGeographyAvailOpt:',
      ...comparedGeographyAvailOpt.map((x) => x.value),
      '\n-on industry ',
      industry
    );
    setInstance(instance + 1);
  }, [
    terms,
    category,
    fromYearToYear,
    restrictToArea,
    comparedGeography,
    industry,
    measure,
    comparedGeographyAvailOpt,
  ]);

  useEffect(() => {
    setMeasure(queryLabels[analyticTab.index]);
  }, [analyticTab, queryLabels]);
  return (
    <div className="filters w-full flex flex-col bg-neutral-color-20 relative justify-between items-end px-5">
      {/* expanded */}
      <div
        className={`${
          isCollapsed ? 'h-0 overflow-y-hidden' : 'h-fit overflow-y-visible'
        }  flex flex-row w-full justify-start gap-5 transiton-all ease-in-out duration-500`}
      >
        {/* Market Analytics*/}
        <div className="pt-5 flex flex-col w-5/12 gap-5">
          <Tab.Group
            onChange={(tab) => {
              setAnalyticTab(AnalyticTabs[tab]);
              if (AnalyticTabs[tab].label != 'Employer Demand')
                setCategory({ name: 'Job role', value: 'job' }); //set termcategory to job role
              setTerms([]); //empty autocomplete
            }}
          >
            <Tab.List className="flex rounded-lg bg-custom-white border p-1">
              {AnalyticTabs.map((analyticTab) => (
                <ToggleTab key={analyticTab.label}>
                  {analyticTab.label}
                </ToggleTab>
              ))}
            </Tab.List>
            <Tab.Panels className="">
              {AnalyticTabs.map((analyticTab, index) => (
                <Tab.Panel className="" key={index}>
                  {/*  display listbox if first tab(employer demand) */}
                  {analyticTab.label === 'Employer Demand' ? (
                    <div className="relative w-full t">
                      <Listbox
                        value={queryLabels[index]}
                        onChange={(e) => {
                          // maybe to change
                          setQueryLabels(
                            queryLabels.map((k, i) => (i === index ? e : k))
                          );
                        }}
                      >
                        <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left ring-1 ring-gray-200 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className="block truncate">
                            {queryLabels[index].name}{' '}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto cursor-pointer z-50 rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none ">
                          {analyticTab.measureOpt.map((value, index) => (
                            <Listbox.Option
                              key={index}
                              value={value}
                              className="pl-3 ui-active:bg-gray-100 ui-active:opacity-100 opacity-80 cursor-pointer"
                            >
                              {value.name}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </div>
                  ) : (
                    <> </>
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* 'Industry */}
          <div className="flex flex-row justify-start gap-5 items-center">
            {/* not enough spacing for labels */}
            {/* <span className="pl-3">Industry</span> */}
            <RadioGroup
              value={industry}
              onChange={(e) => {
                setIndustry(e);
              }}
              className="flex flex-row flex-wrap gap-2"
            >
              {industryList.map((x) => (
                <RadioGroup.Option
                  key={x.key}
                  value={x.value}
                  className={({ active, checked }) =>
                    `${active ? '' : ''}
    ${
      checked
        ? 'bg-brand text-custom-white hover:bg-brand-light'
        : 'bg-custom-white text-brand hover:bg-transparent '
    }
        flex text-sm align-middle cursor-pointer rounded-full px-2 py-1 focus:outline-none border border-brand`
                  }
                >
                  {({ active, checked }) => (
                    <RadioGroup.Label
                      as="span"
                      className={`inline w-full font-medium text-center align-middle my-auto
            
          `}
                    >
                      {x.key}
                    </RadioGroup.Label>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
        </div>

        {/* Term selection */}
        <div className="pt-5 flex flex-col w-1/3  gap-2 ">
          {/* <h2 className="font-medium">Term</h2> */}
          <div className="w-full flex flex-row justify-between">
            {/* Skill Job Tech */}
            <RadioGroup
              value={category}
              onChange={(e) => {
                console.log('category set to ', category);
                setCategory(e);
                setTerms([]); //erase term list
                //setSearchTerm(''); //we'll not need to reset the search term
              }}
              className="flex flex-row rounded-full p-1 gap-2"
            >
              {categoryOptions.map((termCategory) => (
                <RadioGroup.Option
                  key={termCategory.name}
                  value={termCategory}
                  disabled={analyticTab.label != 'Employer Demand'}
                  className={({ active, checked, disabled }) =>
                    `${active ? '' : ''}
                ${
                  checked
                    ? 'bg-brand text-custom-white hover:bg-brand-light'
                    : 'bg-custom-white text-brand hover:bg-transparent '
                }
                ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                   flex text-base cursor-pointer rounded-full px-3 py-1 focus:outline-none border border-brand`
                  }
                >
                  {({ active, checked }) => (
                    <RadioGroup.Label
                      as="span"
                      className={` inline w-full font-medium text-center align-middle 
                        
                      `}
                    >
                      {termCategory.name}
                    </RadioGroup.Label>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            {/* Emerging / All / Tech */}
            <RadioGroup
              value={termGroup}
              onChange={(e) => {
                setTermGroup(e);
                setSearchTerm('');
                console.log('term group set to ', e);
              }}
              className="flex flex-row rounded-full p-1 gap-2"
            >
              {termGroupSelect &&
                termGroupOptions[category.value].length > 1 &&
                termGroupOptions[category.value].map((x) => (
                  <RadioGroup.Option
                    key={x.name}
                    value={x}
                    // disabled={category.value != 'skill'}
                    className={({ active, checked, disabled }) =>
                      `${active ? '' : ''}
                ${
                  checked && !disabled
                    ? 'bg-brand text-custom-white hover:bg-brand-light'
                    : 'bg-custom-white text-brand hover:bg-transparent '
                }${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                   flex text-base cursor-pointer rounded-full px-3 py-1 focus:outline-none border border-brand`
                    }
                  >
                    {({ active, checked }) => (
                      <RadioGroup.Label
                        as="span"
                        className={` inline w-full font-medium text-center align-middle 
                        
                      `}
                      >
                        {x.name}
                      </RadioGroup.Label>
                    )}
                  </RadioGroup.Option>
                ))}
            </RadioGroup>
          </div>

          <Autocomplete
            // suggestions return from query
            multiple
            limitTags={10}
            openOnFocus
            autoComplete
            id={category.value}
            options={
              (category.value === 'job'
                ? termGroupSelect === true && termGroup.value === 'emergent'
                  ? autosuggTerms.data?.emergentjobs || []
                  : autosuggTerms.data?.jobs || []
                : termGroupSelect === true && termGroup.value === 'emergent'
                ? autosuggTerms.data?.emergentskills || []
                : termGroupSelect === true && termGroup.value === 'tech'
                ? autosuggTerms.data?.tech || []
                : autosuggTerms.data?.skills || []
              ).map((x) => x.term || '') //remove nulls
            }
            loading={autosuggTerms.loading}
            value={terms}
            onChange={(e, v) => {
              setTerms(v);
            }}
            sx={{ flexGrow: 1 }}
            renderInput={(params) => (
              <TextField
                // sx={{flexGrow: 1}}
                {...params}
                placeholder={category.name}
                value={searchTerm} //search term value
                //update search term state on field change
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  console.log('serachterm', searchTerm);
                  console.log(
                    'autosugg',
                    termGroup,
                    autosuggTerms.data,
                    'termgroupselect',
                    termGroupSelect
                  );
                }}
              />
            )}
          />
        </div>

        {/*Filters*/}
        <div className="pt-5 flex flex-col w-1/6 gap-5">
          {/* Restrict to area */}
          <div className="gap-1 flex flex-col">
            {/* <h3>Restrict to areas</h3> */}

            <Autocomplete
              // suggestions return from query
              // sx={{ p: 0 }}

              className="bg-white"
              limitTags={10}
              id="area"
              options={areaOptions.data || []}
              loading={areaOptions.loading} // query loading state
              getOptionLabel={(option) =>
                option?.name +
                  ' (' +
                  (option?.area_type || 'nation') +
                  //areaTypeLabel(option?.area_type || 'nation').toLowerCase() +
                  ')' || ''
              }
              isOptionEqualToValue={(
                option: GeographicArea,
                value: GeographicArea
              ) =>
                option?.name === value?.name &&
                option?.area_type === value?.area_type
              }
              value={restrictToArea}
              onChange={(e, v) => {
                setRestrictToArea(v);
                // console.log('restrict to area');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ p: 0 }}
                  placeholder="Area"
                  label="Restrict area"
                  value={searchArea} //search term value
                  //update search term state on field change
                  onChange={(e) => {
                    setSearchArea(e.target.value);
                  }}
                />
              )}
            />
          </div>
          {/* Comparative geographies */}
          <div className="gap-1 flex flex-col">
            {/* <h3>Comparative geographies</h3> */}
            <div className="relative w-full t">
              <Autocomplete
                id="comparative geographies"
                openOnFocus
                value={comparedGeography}
                options={comparedGeographyAllOpt}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={
                  (option) => comparedGeographyAvailOpt.indexOf(option) === -1 // disbale option if it is not available
                }
                isOptionEqualToValue={(
                  option: GeographicSize,
                  value: GeographicSize
                ) =>
                  option?.name === value?.name && option?.value === value?.value
                }
                onChange={(e, v) => {
                  if (v !== null) setComparedGeography(v as GeographicSize);
                  // console.log(v)
                }}
                defaultValue={
                  comparedGeographyAvailOpt.length > 0
                    ? {
                        name: comparedGeographyAvailOpt[0].name,
                        value: comparedGeographyAvailOpt[0].value,
                      } //causes autocomplete to be both controlled and uncontrolled
                    : // undefined
                      undefined
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Comparative geographies" />
                )}
              />
            </div>
          </div>
          {/* Time period slider */}
          <div
            className={`flex flex-col px-2 gap-0 relative ${
              analyticTab.label != 'Employer Demand'
                ? 'opacity-60'
                : 'opacity-100'
            }`}
          >
            <h3 className="text-xs absolute left-4 top-0 text-black opacity-70 ">
              Timeframe
            </h3>
            <div className="pt-4">
              <Slider
                // sx={{ paddingTop: 12, height: 5 }}
                getAriaLabel={() => 'Year range'}
                defaultValue={fromYearToYear}
                value={fromYearToYear}
                onChange={(e, v) => {
                  console.log('slider', v);
                  setFromYearToYear(Array.isArray(v) ? v : [v, v]);
                }}
                valueLabelDisplay="auto"
                // valueLabelFormat={}
                step={1}
                marks
                // ={fromYearToYear.map((year) => ({
                //   value: year,
                //   label: '' + year,
                // }))}
                min={yearDomain[0]}
                max={yearDomain[1]}
              />
            </div>
          </div>
        </div>
      </div>
      {/* expand/collapse  button */}
      <div className="w-1/2 flex flex-row justify-between items-end px-5 ">
        {/* Label */}
        <div
          className={`${
            !isCollapsed ? 'opacity-0 h-0' : 'opacity-100 h-16 py-5'
          } absolute top-0 left-5 whitespace-nowrap overflow-hidden text-left align-right pr-4 flex flex-row transiton-all ease-in-out duration-1500`}
        >
          <button
            className="w-full flex flex-row gap-5 font-medium text-base text-custom-black items-center hover:text-brand focus:outline-none text-right"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {terms === defaultTerms &&
            analyticTab.label === 'Employer Demand' &&
            industry === industryList[0].value ? (
              queryLabels[analyticTab.index].name +
              ' of difficult to fill jobs amongst ' +
              industryList[0].key +
              ' employers by UK ' +
              comparedGeography?.name +
              ' (' +
              fromYearToYear[0] +
              ' - ' +
              fromYearToYear[1] +
              ')'
            ) : (
              <>
                <div className={terms.length > 0 ? 'flex flex-row' : 'hidden'}>
                  {category.name}
                  {/* {terms.length > 1 ? 's' : ''} */}{' '}
                  {queryLabels[analyticTab.index].name.toLowerCase()}
                  {' on '}
                  <span className="text-brand-dark px-1">
                    {' '}
                    {shortenString(terms.join(', '), 100)}{' '}
                  </span>
                  {restrictToArea
                    ? ' in ' +
                      restrictToArea.name +
                      '(' +
                      restrictToArea.area_type +
                      ')'
                    : ''}{' '}
                  compared by {comparedGeography?.name.toLowerCase()}
                  {analyticTab.label === 'Employer Demand'
                    ? ' · ' + fromYearToYear[0] + ' - ' + fromYearToYear[1]
                    : ''}
                </div>
                <div
                  className={terms.length === 0 ? 'flex flex-row' : 'hidden'}
                >
                  {'Please select terms to view data'}
                </div>
              </>
            )}
            <MenuIcon />
          </button>
        </div>
        <button onClick={() => setIsCollapsed(!isCollapsed)} className="">
          {isCollapsed ? (
            // <></>
            <ExpandMoreIcon
              className="h-5 w-5 text-neutral-20 hover:fill-brand my-5 opacity-0 "
              aria-hidden="true"
            />
          ) : (
            <ExpandLessIcon
              className="h-5 w-5 text-neutral-20 hover:fill-brand mb-5"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default AnalyticFiltersPanel;
