import { PieArcSeries, PieChart } from 'reaviz';
import { dataType } from '..';
function DonutChartPanel({ inputData }: { inputData: dataType }) {
  return (
    <>
      <PieChart
        // className="mx-10"
        width={390}
        height={300}
        data={inputData}
        series={
          <PieArcSeries
            cornerRadius={2}
            padAngle={0.01}
            padRadius={200}
            doughnut={true}
            colorScheme={'cybertron'}
          />
        }
      />
    </>
  );
}

export default DonutChartPanel;
