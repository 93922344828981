import { useRecoilState } from 'recoil';
import { useGetSkillClustersQuery } from 'generated/graphql';
import { Series } from 'data-forge';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import ForceGraph2D from 'react-force-graph-2d';

import { interpolate, interpolateExp, interpolateQuadratic } from 'Utils';
import { recoilSkillClustersTerms } from './atoms';

export type Link = {
  id: string;
  sourceName: string;
  targetName: string;
  strength: number;
};

export type Node = {
  id: string;
  neighbors: string[];
  links: Link[];
  size: number;
};

//colour palette
const linkColour = 'rgba(62, 48, 214, 0.324)';
const nodeColour = '#658ce0';
const textColour = '#030338';

const linkHoverColour = 'rgba(54, 69, 229, 0.626)';
const nodeHoverColour = '#a78cda';
const textHoverColour = 'rgb(13, 0, 34)';

// const { useMemo, useState, useCallback } = React;
function SkillClustersViewPanel() {
  const fgRef = useRef();
  const [terms] = useRecoilState(recoilSkillClustersTerms);

  // we need to have at least one term to use for the query (otherwise it will justselect everything and crash)
  // So if there are no selectedTerms, we just chose Planning.
  const displayedTerms = terms?.length ? terms : ['Planning'];
  const serie = new Series(
    useGetSkillClustersQuery({
      variables: {
        where: { ParentSkill: { _in: displayedTerms.map((o) => o) } },
      },
    }).data?.global_top_10_skill_clusters
  );

  const rows = serie.inflate().toArray();

  //domain is the min and max of the similarity
  const domain = [
    serie.select((row) => row.Similarity).min(),
    serie
      .where((row) => row.Similarity < 99) //we'll not use the 100% similarity, which is always the same skill
      .select((row) => row.Similarity)
      .max(),
  ];

  //individualDomains is the min and max of the similarity for each Parentskill
  const individualDomains = serie
    .groupBy((row) => row.ParentSkill)
    .select((g) => ({
      key: g.first().ParentSkill,
      domain: [
        g.select((row) => row.Similarity).min(),
        g.select((row) => row.Similarity).max(),
      ],
    }))
    .toArray();

  //node radius
  const NODE_R = 2;

  const data = useMemo(() => {
    const gData = {
      nodes: Object.fromEntries(
        rows
          .map((row) => row.ChildSkill) //we can probably delete this and use v.ChildSkill as obkect key on the line +2
          .map((v) => [
            v,
            {
              id: v,
              neighbors: [] as string[],
              links: [] as Link[],
              size: 0,
            },
          ])
      ),
      links: Object.fromEntries(
        rows
          .filter((row) => row.ParentSkill !== row.ChildSkill)
          .map((row) => [
            row.id,
            {
              id: row.id + '',
              source: row.ParentSkill,
              target: row.ChildSkill,
              //strength is the interpolate result of the similarity by the domain
              strength: interpolateQuadratic(row.Similarity, domain, [1, 10]),
            },
          ])
      ),
    };

    Object.values(gData.links).forEach((link) => {
      console.log('strength: ', link.strength);
      gData.nodes[link.source].neighbors.push(link.target + '');
      gData.nodes[link.target].neighbors.push(link.source + '');
      gData.nodes[link.source].links.push(link);
      gData.nodes[link.target].links.push(link);
      gData.nodes[link.target].size += link.strength / 8;
      gData.nodes[link.source].size += link.strength / 8;
    });
    return gData;
  }, [rows, domain, individualDomains]);

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(data.nodes[0]);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node: Node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor) =>
        highlightNodes.add(data.nodes[neighbor])
      );
      console.log(Array.from(highlightNodes));
      // console.log('node links', node.links);
      node.links.forEach((link) => highlightLinks.add(link));
    }
    console.log('highlight links', Array.from(highlightLinks));
    // setHoverNode(node);
    updateHighlight();
  };

  const handleLinkHover = (link: Link) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      // highlightLinks.add(link);
      // highlightNodes.add(link.source);
      // highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  const paintLink = useCallback(
    (
      link: {
        source: { x: any; y: any; id: string; size: number };
        target: { x: any; y: any; id: string; size: number };
        id: string;
        strength: number;
      },
      ctx: any
    ) => {
      const { source, target, strength } = link;
      // console.log('source', source.id, '  <- ');
      // const sourceNode = data.nodes[source];
      // const targetNode = data.nodes[target.id];
      // const color =
      //   'rgb(' + interpolateQuadratic(strength, [1, 10], [0, 255]) + ', 0, 0)';
      // ctx.strokeStyle =
      //   'rgba(' + interpolateExp(strength, [1, 10], [0, 1]) + ', 1, 1, .5)';
      // ctx.strokeOpacity = 0.1;
      ctx.strokeStyle = highlightLinks.has(link) ? linkHoverColour : linkColour;
      ctx.lineWidth = strength / 2 || 0.5;
      ctx.beginPath();
      ctx.moveTo(source.x, source.y);
      ctx.lineTo(target.x, target.y);
      ctx.stroke();
    },
    [hoverNode]
  );
  const paint = useCallback(
    (
      node: { x: any; y: any; id: string; size: number } | null,
      ctx: {
        fillRect(arg0: number, arg1: number, arg2: any): unknown;
        textAlign: string;
        textBaseline: string;
        fillText(label: string | undefined, x: any, y: any): unknown;
        font: string;
        measureText(label: string | undefined): {
          height: number;
          width: number;
        };
        beginPath: () => void;
        arc: (
          arg0: any,
          arg1: any,
          arg2: number,
          arg3: number,
          arg4: number,
          arg5: boolean
        ) => void;
        fillStyle: string;
        fill: () => void;
      },
      globalScale: number
    ) => {
      const label = node?.id;
      const fontSize = 16 / globalScale;
      ctx.font = `${fontSize}px Roboto`;
      // const textWidth = ctx.measureText(label).width;
      // const bckgDimensions = [textWidth, fontSize].map(
      //   (n) => n + fontSize * 0.2
      // ); // some padding

      // add ring just for highlighted nodes
      // ctx.beginPath();

      ctx.arc(
        node?.x,
        node?.y,
        NODE_R * 1.1 * (node?.size || 1),
        0,
        2 * Math.PI,
        false
      );
      ctx.fillStyle = highlightNodes.has(node) ? nodeHoverColour : nodeColour;
      // ctx.fillStyle = node === hoverNode ? 'red' : 'orange';
      ctx.fill();

      // text
      ctx.textAlign = 'center';
      ctx.textBaseline = 'top';
      ctx.fillStyle = highlightNodes.has(node) ? textHoverColour : textColour;
      ctx.fillText(label, node?.x, node?.y);

      // ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      // ctx.fillRect(
      //   node?.x || 0 - bckgDimensions[0] / 2,
      //   node?.y || 0 - bckgDimensions[1] / 2,
      //   bckgDimensions[0] || 0
      // );
    },
    [hoverNode]
  );

  // useEffect(() => {
  //   // const fg = fgRef.current;
  //   // if (fg !== undefined) {
  //     fgRef.d3Force('charge').strength(-500);
  //     fg.d3Force('link').distance(140);
  //     fg.d3Force('center').strength(1);
  //     fg.zoomToFit(10, 10);
  //   // }
  // }, []);

  // function onEngineStop() {
  //   const fg = fgRef.current;
  //   if (fg !== undefined) fg.zoomToFit(1000, 10); //the '1000' presents the changed animation
  // }
  //debug
  // return <></>;
  return (
    <div className="w-full fixed left-0 overflow-hidden z-0 bg-white">
      <ForceGraph2D
        graphData={{
          nodes: Object.values(data.nodes),
          links: Object.values(data.links),
        }}
        // zoom={50}
        zoomToFit={true}
        // ref={fgRef}
        warmupTicks={250}
        cooldownTime={0}
        // onEngineStop={() => fgRef.current?.zoomToFit(50, 120)}
        // onEngineStop={onEngineStop}
        nodeRelSize={6}
        // autoPauseRedraw={false}
        // linkWidth={(link: {
        //   strength: number;
        //   source: string;
        //   target: number;
        // }) => link.strength * 3 || 0.5}
        // linkWidth={(link: Link) =>
        //   highlightLinks.has(link) ? 30 : link.strength * 3 || 1
        // }

        // linkOpacity={(link: Link) => (highlightLinks.has(link) ? 1 : 0.5)}
        linkDirectionalParticles={(link: {
          strength: number;
          source: string;
          target: number;
        }) => link.strength || 1}
        linkDirectionalParticlesWidth={0}
        nodeCanvasObjectMode={() => 'after'}
        // linkDirectionalParticleWidth={(link: unknown) =>
        //   highlightLinks.has(link) ? 4 : 0
        // }
        // nodeCanvasObjectMode={(node: Node) =>
        //   highlightNodes.has(node) ? 'before' : undefined
        // }
        // nodeCanvasObject={(node: Node) =>
        //   highlightNodes.has(node) ? highlight : paint
        // }
        nodeCanvasObject={paint}
        linkCanvasObjectMode={() => 'replace'}
        linkOpacity={0.5} //useless
        linkCanvasObject={paintLink}
        nodeColor="rgba(39, 21, 182, 0.032)" //useless
        nodeOpacity={0.5}
        onNodeHover={handleNodeHover}
        onLinkHover={handleLinkHover}
      />
    </div>
  );
}

export default SkillClustersViewPanel;
