import { useFilterValues } from '../VisualFiltersPanel';
import { Series } from 'data-forge';
import { useEffect, useState } from 'react';
import LineChartPanel from './components/LineChartPanel';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { ViewAsListBox, useFetchCareerData, viewOption } from '..';
import { ISeriesConfig, SeriesConfigFn } from 'data-forge/build/lib/series';
import { LoadingSpinner, NoData } from 'components/Misc';

export type dataType = { key: string; data: { key: string; data: number }[] }[];
const defaultData = [
  {
    key: 'No data',
    data: [
      { key: '2010', data: 0 },
      { key: '2022', data: 0 },
    ],
  },
] as dataType;

const useProcessData = () => {
  // console.log('useprocessdata');
  const filters = useFilterValues();
  let displayedData = defaultData;
  //const [displayedData, setDisplayedData] = useState(defaultData); //debug
  // return { data: defaultData, loading: false }; //DEBUG TEST
  //so now let's make all the queries using the filters. select proper query using the filters, make a series out of it group by label and a serie grouped by year (for the total)
  //for both:  if we want change or %change, join the series with the series of the previous year, and calculate the change
  //          else convert it to datatype and concat.
  const fetchData = useFetchCareerData();
  //console.log('fetchdata', fetchData.loading, fetchData.global.length);
  // const update = () => {
  // if (!fetchData.loading) {
  // console.log(
  //   '\nqueried tables lenngths on evolution over time',
  //   fetchData.global.length
  // );
  //why is stillLoading always true before we update the terms twice?
  // console.log('\n\nquery updated, lets make the data');

  //ok, now let's make the data
  // const selectedDataFromQ = fetchData.global as
  //   | Iterable<any>
  //   | Iterator<any, any, undefined>
  //   | ISeriesConfig<number, any>
  //   | SeriesConfigFn<number, any>
  //   | undefined;

  // console.log('selectedDataFromQ', selectedDataFromQ);
  // if (selectedDataFromQ != undefined) {
  //   //calculate the counts

  const queryLabel = (
    filters.queryLabels[filters.analyticTab.index].value + ''
  ).includes('hange')
    ? 'change'
    : 'amount';
  console.log('querylabel', queryLabel);

  const byJobData =
    queryLabel === 'amount' // if it's an amount, we don't need to join with the precedent year
      ? new Series(fetchData.global)
          .groupBy((row) => row.label)
          .select((group) => ({
            key: group?.first()?.label || '',
            data: group
              .orderBy((row) => row.year)
              .select((row) => ({
                key: row.year + '', //.toString(),
                data: row.amount,
              }))
              .inflate()
              .toArray(),
          }))
          .inflate()
          .toArray()
      : new Series(fetchData.global) //if it's a change or a % change, we need to join with the precedent year
          .groupBy((row) => row.label)
          .select((labelgroup) =>
            labelgroup
              .join(
                labelgroup.select((row) => ({
                  year: row.year + 1, //on va joindre avec l'année précédente donc on ajoute 1 pour ensuite faire le join par égalité
                  // Label: row.Label,
                  amount: row.amount,
                })),
                (innerRow) => innerRow.year,

                (innerRow) => innerRow.year,

                (row, prevYearRow) => ({
                  ...row,
                  change: row.amount - prevYearRow.amount,
                })
              )
              .inflate()
          )
          .select((job) => ({
            key: job?.first()?.label || '',
            data: job
              .orderBy((row) => row.year)
              .select((yearRow) => ({
                key: yearRow.year + '', //.toString(),
                data: yearRow.change,
              }))
              .toArray(),
          }))

          .inflate()
          .toArray();

  console.log('data output', byJobData);
  // displayedData = byJobData as dataType; //debug
  //   //setDisplayedData(byJobData as dataType); //debug
  if (byJobData.length > 1) {
    const totalData =
      queryLabel === 'amount'
        ? new Series(fetchData.global)
            .groupBy((row) => row.year)
            .select((group) => ({
              key: group?.first()?.year || 2000 + '', //.toString(),
              data: group.select((row) => row.amount).sum(),
            }))
            .inflate()
            .toArray()
        : new Series(fetchData.global) //if it's a change or a % change, we need to join with the precedent year
            .groupBy((row) => row.label)
            .select((labelgroup) =>
              labelgroup
                .join(
                  labelgroup.select((row) => ({
                    year: row.year + 1, //on va joindre avec l'année précédente donc on ajoute 1 pour ensuite faire le join par égalité
                    // Label: row.Label,
                    amount: row.amount,
                  })),
                  (innerRow) => innerRow.year,

                  (innerRow) => innerRow.year,

                  (row, prevYearRow) => ({
                    ...row,
                    change: row.amount - prevYearRow.amount,
                  })
                )
                .inflate()
            )
            .selectMany((group) => group.bake())
            .groupBy((row) => row.year)
            .orderBy((group) => group?.first()?.year)
            .select((yearGroup) => ({
              key: yearGroup?.first()?.year || 2000 + '', //.toString(),
              data: yearGroup.select((row) => row.change).sum(),
            }))
            .inflate()
            .toArray();

    console.log('data output', byJobData);
    return {
      data: [...byJobData, { key: 'Total', data: totalData }] as dataType,
      loading: fetchData.loading,
    };
  }
  // else return { data: byJobData as dataType, loading: false };
  // }
  //   }
  // };
  // update();
  // useEffect(() => {
  //   console.log('useeffect on evolution over time', filters.instance);
  //   // update();
  // }, [
  //   filters.instance,
  //   // fetchData.global,
  //   // filters.queryLabels,
  //   // filters.analyticTab, //no need to add the what'already used in the queries
  // ]);
  return { data: byJobData, loading: fetchData.loading }; //fetchData.loading };
};

function EvolutionOverTime({
  setViewOptionsWithData,
}: {
  setViewOptionsWithData: any;
}) {
  const query = useProcessData();
  // const query = { data: defaultData, loading: false }; //DEBUG TEST
  const viewOptions = [
    {
      name: 'Line chart',
      icon: StackedLineChartIcon,
      label: 'As Line chart',
      panel: LineChartPanel,
    },
  ] as viewOption[];
  const [selectedView, setSelectedView] = useState(viewOptions[0]);

  //debug
  useEffect(
    () => setViewOptionsWithData({ data: query?.data, ...selectedView }),
    [query?.data, selectedView]
  );

  return (
    <div className="w-full">
      <ViewAsListBox
        selectedView={selectedView}
        viewOptions={viewOptions}
        setSelectedView={setSelectedView}
      />

      <selectedView.panel inputData={query.data || defaultData} />
      {query.loading ? (
        <LoadingSpinner />
      ) : query.data?.length === 0 || query.data[0].key === 'No data' ? (
        <NoData />
      ) : (
        <></>
      )}
      {/* {query.data?.length === 0 || query.data[0].key === 'No data' ? (
        <NoData />
      ) : query.loading ? (
        <LoadingSpinner />
      ) : (
        <></>
      )} */}
    </div>
  );
}

export default EvolutionOverTime;
