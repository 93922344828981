import { ReactNode, useState } from 'react';
import { Listbox } from '@headlessui/react';
import {
  ChartBarIcon,
  ChartPieIcon,
  ChevronDownIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  MapIcon,
  PlusIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  PrinterIcon,
  SaveAsIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { toast } from 'react-hot-toast';
import DonutChartPanel from '../VisualTab/AreaComparison/components/DonutChartPanel';
import BarChartPanel from '../VisualTab/AreaComparison/components/BarChartPanel';
import LineChartPanel from '../VisualTab/EvolutionOverTime/components/LineChartPanel';
import { useFilterValues } from '../VisualTab/VisualFiltersPanel';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

function ReportingTab() {
  const filters = useFilterValues();
  const [fullWidth, setFullWidth] = useState(false);
  const descriptionLocation = filters.restrictToArea?.name || 'UK';
  const descriptionYears =
    filters.fromYearToYear.indexOf(0) === -1
      ? 'from ' + filters.fromYearToYear[0] + ' to ' + filters.fromYearToYear[1]
      : '';

  let [savedCharts, setSavedCharts]: any = useState(
    JSON.parse(sessionStorage.getItem('ars') || '[]')
  );
  let [chartsToRender, setChartsToRender]: any = useState([
    {
      name: 'Comment',
      data: `<h2 class="text-2xl">Foresighter Reporting System</h2>
              Describe what you see`,
    },
  ]);

  let getBGColor = (name: string) => {
    switch (name) {
      case 'Map':
        return 'bg-green-500';
        break;
      case 'Bar chart':
        return 'bg-yellow-500';
        break;
      case 'Line chart':
        return 'bg-purple-500';
        break;

      default:
        return 'bg-pink-500';
        break;
    }
  };

  let getIcon = (name: string) => {
    switch (name) {
      case 'Map':
        return <MapIcon />;
        break;
      case 'Bar chart':
        return <ChartBarIcon />;
        break;
      case 'Line chart':
        return <PresentationChartLineIcon />;
        break;
      case 'Donut Chart':
        return <ChartPieIcon />;
        break;
      default:
        return <PresentationChartBarIcon />;
        break;
    }
  };

  console.log('tab', filters.analyticTab.index);

  const content = (
    <div className="overflow-visible w-full h-full">
      <div className="relative w-full h-full flex flex-col">
        {/* panels */}
        <div
          className={`relative w-full flex gap-5 p-5 bg-neutral-90 ${
            fullWidth ? 'flex-col ' : 'flex-row ' // no gap in full width mode
          }`}
        >
          {/* Available Entries */}
          <ViewPanel
            title="All saved charts"
            addClassName={
              fullWidth
                ? 'w-full h-screen print:hidden'
                : 'w-1/4 h-full print:hidden'
            }
          >
            <ul
              role="list"
              className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 border-b pb-3"
            >
              <li
                className="col-span-1 flex rounded-md shadow-sm cursor-pointer"
                onClick={() => window?.print()}
              >
                <div
                  className={classNames(
                    'bg-gray-500',
                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                  )}
                >
                  <div className="w-6 h-6">
                    <PrinterIcon />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <span className="font-medium text-gray-900 hover:text-gray-600">
                      Print report
                    </span>
                    <p className="text-gray-500">Save as PDF</p>
                  </div>
                </div>
              </li>
              <li
                className="col-span-1 flex rounded-md shadow-sm cursor-pointer"
                onClick={() => {
                  console.log(
                    'JSON.stringify(chartsToRender)',
                    JSON.stringify(chartsToRender)
                  );
                  sessionStorage.setItem(
                    'ars-report',
                    JSON.stringify(chartsToRender)
                  );
                  toast.success('Successfully saved report');
                }}
              >
                <div
                  className={classNames(
                    'bg-gray-500',
                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                  )}
                >
                  <div className="w-6 h-6">
                    <SaveAsIcon />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <span className="font-medium text-gray-900 hover:text-gray-600">
                      Save report
                    </span>
                    <p className="text-gray-500">Including charts and text</p>
                  </div>
                </div>
              </li>
              <li
                className="col-span-1 flex rounded-md shadow-sm cursor-pointer"
                onClick={() => {
                  let arsReport = JSON.parse(
                    sessionStorage.getItem('ars-report') || '[]'
                  );
                  setChartsToRender(arsReport);
                  toast.success('Successfully loaded report');
                }}
              >
                <div
                  className={classNames(
                    'bg-gray-500',
                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                  )}
                >
                  <div className="w-6 h-6">
                    <FolderOpenIcon />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <span className="font-medium text-gray-900 hover:text-gray-600">
                      Load report
                    </span>
                    <p className="text-gray-500">Including charts and text</p>
                  </div>
                </div>
              </li>
            </ul>
            <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6">
              <li className="col-span-1 flex rounded-md shadow-sm cursor-pointer">
                <div
                  className={classNames(
                    'bg-orange-500',
                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                  )}
                >
                  <div className="w-6 h-6">
                    <DocumentTextIcon />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <span className="font-medium text-gray-900 hover:text-gray-600">
                      Add Comment
                    </span>
                    <p className="text-gray-500">Appends a text comment</p>
                  </div>
                  <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() =>
                        setChartsToRender([
                          ...chartsToRender,
                          { name: 'Comment', data: '' },
                        ])
                      }
                    >
                      <span className="sr-only">Open options</span>
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </li>
              {savedCharts.map((savedChart: any, savedChartIndex: number) => (
                <li
                  key={savedChart.name}
                  className="col-span-1 flex rounded-md shadow-sm"
                >
                  <div
                    className={classNames(
                      getBGColor(savedChart.name),
                      'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                    )}
                  >
                    <div className="w-6 h-6">{getIcon(savedChart.name)}</div>
                  </div>
                  <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <span className="font-medium text-gray-900 hover:text-gray-600">
                        <>
                          {(savedChart.name||'').replace('chart', 'Chart')}{' '}
                          <span
                            className="inline-block h-4 w-4 cursor-pointer"
                            style={{ paddingTop: '2px' }}
                            onClick={() => {
                              sessionStorage.setItem(
                                'ars',
                                JSON.stringify([
                                  ...savedCharts.slice(0, savedChartIndex),
                                  ...savedCharts.slice(savedChartIndex + 1),
                                ])
                              );
                              setSavedCharts([
                                ...savedCharts.slice(0, savedChartIndex),
                                ...savedCharts.slice(savedChartIndex + 1),
                              ]);
                            }}
                          >
                            <TrashIcon />
                          </span>
                        </>
                      </span>
                      <p className="text-gray-500">
                        {new Date(savedChart.savedAt).toLocaleString('en-GB')}
                      </p>
                    </div>
                    <div className="flex-shrink-0 pr-2">
                      <button
                        type="button"
                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          setChartsToRender([...chartsToRender, savedChart])
                        }
                      >
                        <span className="sr-only">Open options</span>
                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </ViewPanel>

          {/* canvas */}
          <div className={` ${'w-1/2'} flex flex-col flex-grow gap-5 `}>
            {chartsToRender.map((chartToRender: any, index: number) => (
              <>
                <div
                  key={index}
                  className="bg-white relative shadow rounded-lg transition-all duration-500 p-5"
                >
                  {chartToRender?.name === 'Map' ? (
                    <div className="flex flex-col items-center">
                      <img
                        width="300px"
                        alt="Map raw"
                        src={chartToRender.data}
                      ></img>
                    </div>
                  ) : chartToRender?.name === 'Line chart' ? (
                    <LineChartPanel
                      inputData={chartToRender.data}
                    ></LineChartPanel>
                  ) : chartToRender?.name === 'Bar chart' ? (
                    <BarChartPanel
                      inputData={chartToRender.data}
                    ></BarChartPanel>
                  ) : chartToRender?.name === 'Donut Chart' ? (
                    <DonutChartPanel
                      inputData={chartToRender.data}
                    ></DonutChartPanel>
                  ) : chartToRender?.name === 'Comment' ? (
                    <div
                      className=""
                      contentEditable
                      onBlur={(e) => {
                        chartsToRender[index].data = e.currentTarget.innerHTML;
                      }}
                      dangerouslySetInnerHTML={{
                        __html: chartToRender?.data || 'Describe what you see',
                      }}
                    ></div>
                  ) : (
                    <span>
                      Render Element with JSON {JSON.stringify(chartToRender)}
                    </span>
                  )}
                </div>
              </>
            ))}
            {/* enable evolution over timeonly for employer demand */}
            {/* {filters.analyticTab.index === 0 ? (
              <ViewPanel
                title="Evolution over time"
                description={descriptionLocation + ' ' + descriptionYears}
              >
                <EvolutionOverTime />
              </ViewPanel>
            ) : (
              <></>
            )}
            <ViewPanel
              title="Comparison by area"
              description={`Compared by ${filters.comparedGeography?.name} ${descriptionYears}`}
            >
              <AreaComparison/>
            </ViewPanel> */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
  return content;
}

function ViewPanel({
  children,
  title = 'Untitled',
  description = '',
  addClassName = 'w-full',
  ...props
}: {
  children?: ReactNode;
  addClassName?: string;
  title?: string;
  description?: string;
}) {
  return (
    <div
      className={`bg-white relative shadow rounded-lg transition-all duration-500 p-5 ${
        addClassName + ' '
      }`}
    >
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-col">
          <h2 className="font-bold text-lg">{title}</h2>
          <span>{description}</span>
        </div>
        {children}
      </div>
    </div>
  );
}

export default ReportingTab;

export type viewOption = {
  name: string;
  icon: any; // () => ReactJSXElement;
  label: string;
  panel: any; // ReactJSXElement;
};

// for the view as
export function ViewAsListBox({
  selectedView,
  viewOptions,
  setSelectedView,
}: {
  selectedView: viewOption;
  viewOptions: viewOption[];
  setSelectedView: (view: viewOption) => void;
}) {
  return (
    <div className="flex w-44 absolute right-5 top-5">
      <Listbox value={selectedView} onChange={setSelectedView}>
        <Listbox.Button
          className={
            (viewOptions.length > 1 ? 'cursor-pointer ' : 'cursor-default ') +
            'relative w-full rounded-lg bg-white py-2 pl-3 pr-10 text-left ring-1 ring-gray-200 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
          }
        >
          <span className="block truncate">
            <selectedView.icon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className="ml-2">{selectedView.label}</span>
          </span>
          {viewOptions.length > 1 ? (
            <span className="pointer-events-none absolute inset-y-0 right-auto flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          ) : (
            <></>
          )}
        </Listbox.Button>
        {viewOptions.length > 1 ? (
          <Listbox.Options className="absolute mt-10 max-h-60 w-full overflow-visible cursor-pointer z-50 rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none ">
            {viewOptions.map((viewOption) => (
              <Listbox.Option
                key={viewOption.name}
                value={viewOption}
                className="pl-3 ui-active:bg-gray-100 ui-active:opacity-100 opacity-80 cursor-pointer"
              >
                <div className="flex flex-row">
                  <viewOption.icon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2">{viewOption.label}</span>
                </div>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        ) : (
          <></>
        )}
      </Listbox>
    </div>
  );
}
