import { Series } from 'data-forge';
import { useEffect, useState } from 'react';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useRecoilState } from 'recoil';
import { useFilterValues } from '../VisualFiltersPanel';
import { ISeriesConfig, SeriesConfigFn } from 'data-forge/build/lib/series';
import { ViewAsListBox, useFetchCareerData, viewOption } from '..';
import { LoadingSpinner, NoData } from 'components/Misc';
import DonutChartPanel from './components/DonutChartPanel';
import BarChartPanel from './components/BarChartPanel';
import { defaultData } from '../atoms';

export type dataType = { key: string; areaCode: string; data: number }[];

export const useAreaCompData = () => {
  const filters = useFilterValues();
  const fetchData = useFetchCareerData();
  const selectedDataFromQ = fetchData.geocompared;

  const queryLabel =
    filters.analyticTab.label === 'Market Value'
      ? 'salary'
      : (filters.queryLabels[filters.analyticTab.index].name + '').includes(
          'hange'
        )
      ? 'change'
      : 'amount';

  const byAreaData =
    queryLabel === 'amount' || queryLabel === 'salary' // if it's an amount, we don't need to join with the precedent year
      ? new Series(selectedDataFromQ)
          .groupBy((row) => row.careers_area?.id)
          .select((areaGroup) => ({
            key: areaGroup?.first()?.careers_area?.name || '', //using relation here
            data: areaGroup.select((row) => row.amount).sum(),
            areaCode: areaGroup?.first()?.careers_area?.id || '',
          }))
          .orderBy((row) => row.data)
          .inflate()
          .toArray()
      : new Series(selectedDataFromQ) //if it's a change, we need to join with the precedent year
          .groupBy((row) => row.careers_area?.id)
          .select((areaGroup) =>
            //je sélectionne toutes les lignes avec JobDate qui contient l'année max
            areaGroup.where(
              (row) => row.year === areaGroup.select((row2) => row2.year).max()
            )
          )
          //je calcule la somme des Amount
          .select((finalYRows) => ({
            AreaName: finalYRows.first().careers_area?.name || '',
            AreaCode: finalYRows.first().careers_area?.id || '',
            Sum: finalYRows.select((row) => row.amount).sum(),
          }))
          //je join avec les lignes qui contiennent l'année min
          .join(
            new Series(selectedDataFromQ) //if it's a change, we need to join with the precedent year
              .groupBy((row) => row.careers_area?.id)
              .select((areaGroup) =>
                //je sélectionne toutes les lignes avec JobDate qui contient l'année max
                areaGroup.where(
                  (row) =>
                    row.year === areaGroup.select((row2) => row2.year).min()
                )
              )
              //je calcule la somme des Amount
              .select((finalYRows) => ({
                AreaName: finalYRows.first().careers_area?.name || '',
                AreaCode: finalYRows.first().careers_area?.id || '',
                Sum: finalYRows.select((row) => row.amount).sum(),
              })),
            (row) => row.AreaName,
            (row) => row.AreaName,
            (finalRow, initialRow) => ({
              AreaName: finalRow.AreaName,
              AreaCode: finalRow.AreaCode,
              //debug
              FinalSum: finalRow.Sum,
              InitialSum: initialRow.Sum,
              Change: finalRow.Sum - initialRow.Sum,
            })
          )

          .inflate()
          .select((areaRow) => ({
            key: areaRow.AreaName,
            data: areaRow.Change,
            areaCode: areaRow.AreaCode,
          }))
          .orderBy((row) => row.data)

          .toArray(); //OMG

  return {
    data: byAreaData,
    loading: fetchData.loading,
  };
};

function AreaComparison({
  setViewOptionsWithData,
}: {
  setViewOptionsWithData: any;
}) {
  const query = useAreaCompData();
  !query.loading && console.log('AreaComparison: ', query.data?.length);
  const viewOptions = [
    {
      name: 'Bar chart',
      icon: BarChartIcon,
      label: 'As Bar chart',
      panel: BarChartPanel,
    },
    {
      name: 'Donut Chart',
      icon: DonutLargeIcon,
      label: 'As Donut chart',
      panel: DonutChartPanel,
    },
  ] as viewOption[];
  const [selectedView, setSelectedView] = useState(viewOptions[0]);

  useEffect(
    () => setViewOptionsWithData({ data: query?.data, ...selectedView }),
    [query?.data, selectedView]
  );
  
  return (
    <div className="w-full flex flex-col items-center">
      <ViewAsListBox
        selectedView={selectedView}
        viewOptions={viewOptions}
        setSelectedView={setSelectedView}
      />

      <selectedView.panel inputData={query.data || defaultData} />
      {query.loading ? (
        <LoadingSpinner />
      ) : query.data?.length === 0 || query.data[0].key === 'No data' ? (
        <NoData />
      ) : (
        <></>
      )}
      {query.data.some((row) => row.data < 0) &&
      selectedView.name === 'Donut Chart' ? (
        <div className="absolute rounded p-5 text-center content-center text-red-500 top-1/2 bg-custom-white bg-opacity-70">
          <span>
            This kind kind of charts won't display the negative values
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AreaComparison;
