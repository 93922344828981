import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { atom, useRecoilState } from 'recoil';

export const recoilValuesToDisplay = atom({
  key: 'mapData',
  default: {
    type: 'FeatureCollection',
    features: [],
  } as GeoJSON.FeatureCollection<GeoJSON.Point>,
});

export const recoilCursor = atom({
  key: 'cursor',
  default: 'default' as 'pointer' | 'default',
});
