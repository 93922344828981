import {
  Area,
  DiscreteLegend,
  DiscreteLegendEntry,
  Gradient,
  GradientStop,
  Line,
  LinearXAxis,
  LinearXAxisTickLabel,
  LinearXAxisTickLine,
  LinearXAxisTickSeries,
  LinearYAxis,
  StackedAreaChart,
  StackedAreaSeries,
} from 'reaviz';
import { useFilterValues } from '../../VisualFiltersPanel';
import { dataType } from '..';
import { MulticolorScheme } from 'colourSchemes';

function LineChartPanel({ inputData }: { inputData: dataType }) {
  const lineStroke = 2;
  const filters = useFilterValues();

  //Object where key is data.key and value is the associated colour -> (colorTable['Account director'] = '#121212')
  const colorTable = Object.fromEntries(
    inputData.map((value, index) => [
      value.key,
      MulticolorScheme[index % MulticolorScheme.length],
    ])
  );

  return (
    <div className="pt-5">
      <StackedAreaChart
        height={500}
        xAxis={
          <LinearXAxis
            // type="category"
            // type={undefined}
            type="value"
            // type="category"
            // roundDomains={true}
            domain={filters.fromYearToYear} //to do: start a year later if it's Change
            tickSeries={
              <LinearXAxisTickSeries
                line={<LinearXAxisTickLine position="center" />}
                label={<LinearXAxisTickLabel padding={15} />}
                //tickSize={100}
              />
            }
            // tickSeries={<LinearXAxisTickSeries line={null} label={null} />}
          />
        }
        yAxis={<LinearYAxis type="value" axisLine={null} position="start" />}
        gridlines={null}
        series={
          <StackedAreaSeries
            //animated
            type="grouped"
            //type="stacked"
            line={
              <Line
                //strokeWidth={lineStroke}
                style={(data: { key: string }[]) => {
                  // console.log('data key', data[0].key);
                  return data[0].key === 'Total'
                    ? { strokeDasharray: '4', strokeWidth: '1' }
                    : { strokeWidth: { lineStroke } };
                }}
              />
            }
            colorScheme={(_data) => {
              return colorTable[_data.key || _data[0].key];
            }}
            area={
              <Area
                gradient={
                  <Gradient
                    stops={[
                      <GradientStop key={1} offset="0%" stopOpacity={0.0} />, //0.05
                      <GradientStop key={2} offset="100%" stopOpacity={0} />, //0.3
                    ]}
                  />
                }
              />
            }
          />
        }
        data={inputData}
      ></StackedAreaChart>

      <DiscreteLegend
        style={{ width: '100%' }}
        className="pt-5 pl-10"
        entries={[
          ...inputData
            .slice(0)
            .reverse()
            .map((datarow, index) =>
              datarow.key === 'Total' ? (
                <div className="hidden" key={index}></div>
              ) : (
                <DiscreteLegendEntry
                  style={{ padding: '5' }}
                  label={datarow.key}
                  color={colorTable[datarow.key]}
                />
              )
            ),
        ]}
      />
    </div>
  );
}

export default LineChartPanel;
