import styles from '../styles/pages/Profile.module.css';

import { FormEvent, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Input from '../components/Input';

import { toast } from 'react-hot-toast';
import { useUserId } from '@nhost/react';
import { useGetUserQuery } from 'generated/graphql';
import { useUpdateUserMutation } from 'generated/graphql';

const Profile = () => {
  const id = useUserId();
  const { loading, error, data } = useGetUserQuery({ variables: { id } });
  const user = data?.user;

  const [firstName, setFirstName] = useState(user?.metadata?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.metadata?.lastName ?? '');
  const [role, setRole] = useState(user?.metadata?.role ?? '');
  const [organisation, setOrganisation] = useState(
    user?.metadata?.organisation ?? ''
  );
  const [department, setDepartment] = useState(
    user?.metadata?.department ?? ''
  );

  const isFirstNameDirty = firstName !== user?.metadata?.firstName;
  const isLastNameDirty = lastName !== user?.metadata?.lastName;
  const isRole = role !== user?.metadata?.role;
  const isOrganisation = organisation !== user?.metadata?.organisation;
  const isDepartment = department !== user?.metadata?.department;
  const isProfileFormDirty =
    isFirstNameDirty ||
    isLastNameDirty ||
    isRole ||
    isOrganisation ||
    isDepartment;

  const [mutateUser, { loading: updatingProfile }] = useUpdateUserMutation();
  const updateUserProfile = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await mutateUser({
        variables: {
          id: user?.id,
          displayName: `${firstName} ${lastName}`.trim(),
          metadata: {
            firstName,
            lastName,
            role,
            organisation,
            department,
          },
        },
      });
      toast.success('Updated succesfully', { id: 'updateProfile' });
    } catch (error) {
      toast.error(`'Unable to update profile'${error}`, {
        id: 'updateProfile',
      });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.info}>
          <h2>Profile</h2>
          <p>Update your personal information.</p>
        </div>

        <div className={styles.card}>
          <form onSubmit={updateUserProfile} className={styles.form}>
            <div className={styles['form-fields']}>
              <div className={styles['input-group']}>
                <Input
                  type="text"
                  label="First name"
                  value={firstName}
                  onChange={(e: FormEvent<HTMLFormElement>) =>
                    setFirstName((e.target as HTMLTextAreaElement).value)
                  }
                  required
                  disabled={updatingProfile}
                />
                <Input
                  type="text"
                  label="Last name"
                  value={lastName}
                  onChange={(e: FormEvent<HTMLFormElement>) =>
                    setLastName((e.target as HTMLTextAreaElement).value)
                  }
                  required
                  disabled={updatingProfile}
                />
              </div>
              <div className={styles['input-email-wrapper']}>
                <Input
                  type="email"
                  label="Email address"
                  value={user?.email}
                  readOnly
                />
              </div>
            </div>
            <hr />
            <div className="p-8">
              <div className={styles['input-group']}>
                <Input
                  label="Role"
                  placeholder="Role*"
                  value={role}
                  onChange={(e: FormEvent<HTMLFormElement>) =>
                    setRole((e.target as HTMLTextAreaElement).value)
                  }
                  required
                />
                <Input
                  label="Organisation"
                  placeholder="Organisation*"
                  value={organisation}
                  onChange={(e: FormEvent<HTMLFormElement>) =>
                    setOrganisation((e.target as HTMLTextAreaElement).value)
                  }
                />
                <Input
                  label="Department/Unit"
                  placeholder="Department*"
                  value={department}
                  onChange={(e: FormEvent<HTMLFormElement>) =>
                    setDepartment((e.target as HTMLTextAreaElement).value)
                  }
                  required
                />
              </div>
            </div>

            <div className={styles['form-footer']}>
              <button
                type="submit"
                disabled={!isProfileFormDirty || updatingProfile}
                className={styles.button}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
