import SkillClustersFilterPanel from './SkillClustersFilterPanel';
import SkillClustersViewPanel from './SkillClustersViewPanel';

function SkillClusters() {
  return (
    <>
      <SkillClustersFilterPanel />
      <SkillClustersViewPanel />
    </>
  );
}

export default SkillClusters;
