import { Combobox, RadioGroup } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { atom, useRecoilState } from 'recoil';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import {
  recoilTopic,
  recoilAdType,
  recoilRequirement,
  adTypeOptions,
  recoilSearchText,
  recoilTimePeriod,
  timePeriodOptions,
} from './atoms';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  TextField,
} from '@mui/material';
import { CustomComboBox, SubmitButton } from 'components/Input';
import { ReactNode, useEffect, useState } from 'react';
import { inputTexts } from '../../version_variables/inputtexts';
// import from 'list-files';
// import path from 'path';
type TextFile = {
  requirement: string;
  textsugg: string;
};

function SearchSettings() {
  const [topic, setTopic] = useRecoilState(recoilTopic);
  const [requirement, setRequirement] = useRecoilState(recoilRequirement);
  const [adType, setAdType] = useRecoilState(recoilAdType);

  const [textAreaInput, setTextAreaInput] = useState('');
  const [searchText, setSearchText] = useRecoilState(recoilSearchText);
  const [timePeriod, setTimePeriod] = useRecoilState(recoilTimePeriod);

  const [preLoadedInputs, setPreLoadedInputs] = useState<TextFile[]>(
    inputTexts.map((k) => ({ requirement: k.title, textsugg: k.content }))
  );
  const [selectedRequirement, setSelectedRequirement] = useState(undefined);
  // const [requirementQuery, setRequirementQuery] = useState('');

  return (
    <div className="flex flex-row p-5 gap-5  w-full max-w-7xl">
      <div className="flex flex-col w-1/3 gap-5">
        <div className="flex flex-col w-80">
          <h2 className="text-base font-medium pb-2">Requirement Statement</h2>
          <Autocomplete
            openOnFocus
            autoComplete
            loading={preLoadedInputs.length === 0}
            id="requirement-statement"
            options={preLoadedInputs}
            getOptionLabel={(option) => option.requirement}
            isOptionEqualToValue={(option, value) =>
              option.requirement === value.requirement
            }
            value={requirement}
            onChange={(e, v) => {
              if (v != null) {
                setRequirement(v);
                setTextAreaInput(v?.textsugg ?? '');
              }
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Requirement" />
            )}
          />
        </div>
        {adType === 'Jobs' ? (
          <div className="flex flex-col w-52">
            <h2 className="font-medium text-base pb-1">Time period</h2>
            <CustomComboBox
              filterValue={timePeriod}
              filterValueSetter={setTimePeriod}
              options={timePeriodOptions}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-col w-5/12 pr-4">
        <h2 className="text-base font-medium pb-2">AI Search</h2>
        <TextareaAutosize
          value={textAreaInput}
          onChange={(e) => setTextAreaInput(e.target.value)}
          minRows={5}
          placeholder="Select your topic and requirement statement to autogenerate a search query"
          className="p-5 border border-1 border-gray-300 rounded "
        />
      </div>
      <div className="flex flex-col w-1/6 justify-between">
        <div className="flex flex-col w-52">
          <h2 className="text-base font-medium pb-2">Show</h2>
          <RadioGroup
            value={adType}
            onChange={setAdType}
            className="flex flex-row rounded-full gap-2"
          >
            {adTypeOptions.map((opt) => (
              <RadioGroup.Option
                value={opt}
                key={opt}
                className="flex flex-row items-center px-3 py-1 rounded-full cursor-pointer border border-brand text-brand ui-checked:bg-brand ui-checked:text-custom-white"
              >
                {opt}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <SubmitButton onClick={() => setSearchText(textAreaInput)}>
          Search
          {/* <button type="submit" onClick=></button> */}
        </SubmitButton>
      </div>

      {/* results */}
    </div>
  );
}

export default SearchSettings;
