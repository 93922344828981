import styles from '../styles/components/Layout.module.css';
import '../styles/output.css';
import '../styles/globals.css';
import { Fragment } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  // CogIcon,
  HomeIcon,
  LogoutIcon,
  UserIcon,
} from '@heroicons/react/outline';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Avatar from './Avatar';
import { useSignOut, useUserId } from '@nhost/react';
import { gql, useQuery } from '@apollo/client';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGetUserQuery } from '../generated/graphql';
import { AppName, AppTitle } from 'Utils';
import { AppVersion } from 'version_variables/version_variables';
// import { Palette } from '@mui/material'; '@mui/material/styles';
const Layout = () => {
  const id = useUserId();

  const { loading, error, data } = useGetUserQuery({ variables: { id } });
  const user = data?.user;
  const { signOut } = useSignOut();

  const menuItems = [
    {
      label: 'Dashboard',
      href: '/',
      icon: HomeIcon,
    },
    {
      label: 'Profile',
      href: '/profile',
      icon: UserIcon,
    },
    {
      label: 'Logout',
      onClick: () => signOut(),
      icon: LogoutIcon,
    },
  ];

  return (
    <div className="layout h-screen w-screen bg-gray-50 flex flex-col font-body text-custom-black">
      {/* <div className="org-header w-full h-20 hidden flex-row bg-brand "></div>
      <div className="org-dashboard w-full  hidden flex-grow flex-row">
        <div className="org-nav h-full w-44 flex flex-col  bg-slate-400">
          <div className="w-full flex-grow bg-slate-200"></div>
        </div>
        <div className="org-main h-full flex-grow flex flex-col bg-red-100">
          <div className="tab-list w-full h-20 bg-white"></div>
          <div className="filters w-full h-20 bg-blue-200"></div>
          <div className="ticker w-full h-16 bg-pink-200"></div>
          <div className="visual w-full flex-grow relative overflow-hidden bg-green-50">
            <div className="absolute w-full h-full overflow-auto bg-orange-400 p-5">
              <div className="h-screen w-screen bg-purple-300"></div>
            </div>
          </div>
        </div>
      </div> */}
      <header className="w-full flex flex-row justify-between bg-brand">
        {/* <div className=""> */}
        {/* <Link to="/">
            <img src={process.env.PUBLIC_URL + 'logo.svg'} alt="logo" />
          </Link> */}
        <h1 className="title flex flex-col text-left pl-5 pt-3 pb-4 text-custom-white gap-0">
          <span className="text-3xl font-semibold">{AppName} </span>
          <span className="text-[11px] font-light text-center">
            {AppVersion}
          </span>
        </h1>
        <Menu as="div" className={`{styles.menu} p-5 z-50`}>
          <Menu.Button className={styles['menu-button']}>
            {/* <Avatar src={user?.avatarUrl} alt={user?.displayName} /> */}
            {/* <ChevronDownIcon /> */}
            <div className="text-custom-white">
              <AccountCircleOutlinedIcon color="inherit" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter={styles['menu-transition-enter']}
            enterFrom={styles['menu-transition-enter-from']}
            enterTo={styles['menu-transition-enter-to']}
            leave={styles['menu-transition-leave']}
            leaveFrom={styles['menu-transition-leave-from']}
            leaveTo={styles['menu-transition-leave-to']}
          >
            <Menu.Items className={styles['menu-items-container']}>
              <div className={styles['menu-header']}>
                {/* <Avatar src={user?.avatarUrl} alt={user?.displayName} /> */}
                <div className={styles['user-details']}>
                  <span>{user?.displayName}</span>
                  <span className={styles['user-email']}>{user?.email}</span>
                </div>
              </div>

              <div className={styles['menu-items']}>
                {menuItems.map(({ label, href, onClick, icon: Icon }) => (
                  <div key={label} className={styles['menu-item']}>
                    <Menu.Item>
                      {href ? (
                        <Link to={href}>
                          <Icon />
                          <span>{label}</span>
                        </Link>
                      ) : (
                        <button onClick={onClick}>
                          <Icon />
                          <span>{label}</span>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        {/* </div> */}
      </header>
      {/* debug */}
      <div className="org-dashboard w-full hidden flex-grow flex-row">
        <div className="org-nav h-full w-44 flex flex-col  bg-slate-400">
          <div className="w-full flex-grow bg-slate-200"></div>
        </div>
        <div className="org-main h-full flex-grow flex flex-col bg-red-100">
          <div className="tab-list w-full h-20 bg-white"></div>
          <div className="filters w-full h-20 bg-blue-200"></div>
          <div className="ticker w-full h-16 bg-pink-200"></div>
          <div className="visual w-full flex-grow relative overflow-hidden bg-green-50">
            <div className="absolute w-full h-full overflow-auto bg-orange-400 p-5">
              <div className="h-screen w-screen bg-purple-300"></div>
            </div>
          </div>
        </div>
      </div>
      <main className="w-full flex-grow flex">
        {/* <div className={styles['main-container']}> */}
        {/* className={styles['main-container']}> */}
        {error ? (
          <div className="w-full h-full text-center text-warning">
            <p>Something went wrong. Try to refresh the page.</p>
            <p>{`${error}`}</p>
          </div>
        ) : !loading ? (
          <Outlet context={{ user }} />
        ) : (
          <ClipLoader />
        )}
        {/* </div> */}
      </main>
    </div>
  );
};

export default Layout;
