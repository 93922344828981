import Button from "@mui/material/Button";
import { courseData } from ".";
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import { JobAdDescription, jobData, one_day } from '.';
import { recoilViewport } from "components/AnalyticsPanel/VisualTab/atoms";
import { useRecoilState } from "recoil";
import { recoilSelectedSearchTab } from "../atoms";
import { useEffect, useRef, useState } from "react";
import CompressIcon from '@mui/icons-material/Compress';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { Configuration, OpenAIApi } from "openai";
import ClipLoader from "react-spinners/ClipLoader";
import EngineeringIcon from '@mui/icons-material/Engineering';

async function fetchGPT(text: string) {
    const configuration = new Configuration({
        organization: "org-Ex36yTbrNFMpPutHF62HK8FW",
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    const openai = new OpenAIApi(configuration);
    // const response = await openai.listEngines();
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + String(process.env.REACT_APP_OPENAI_API_KEY)
        },
        body: JSON.stringify({
            // model: "text-davinci-003",
            prompt: text,
            temperature: 1,
            max_tokens: 100,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 1,
        })
      };
    const res = await fetch('https://api.openai.com/v1/engines/text-davinci-003/completions', requestOptions);
return res.json();    
}

const formatText = (text: string) =>{
  // console.log(text);
  // text.replace(' • ', '<br>• ');
  // text.replace(' - ', '<br>- ');
  // console.log('formatted', text);
  // trim the first line return
  text = text.trim();
  text.replace('Cannot provide further skills summary.', '');
  return text;
}

function GPTFeatures ({ title, description, preload=false}: { title: string, description: string, preload?: boolean}) {
    const [summarizing, setSummarizing] = useState(false);
    const [summaryText, setSummaryText] = useState('');
    const summaryFetchedRef = useRef(false);
    const [skillsDisplaying, setSkillsDisplaying] = useState(false);
    const [skillsText, setSkillText] = useState('');
    const skillsFetchedRef = useRef(false);

    const summaryPrompt = "You are a helpful assistant that will assist the user in extracting mentions of skills from the queries and summarising the definitions using the input. Only if the whole query doesn't contain enough information, return 'Cannot provide further skills summary'. You need to provide a newline separated markdown list of skill name with a concise single sentence description. There should be no other text.";
    const skillsPrompt = "You are a helpful assistant that will assist the user in extracting mentions of skills from the queries. You need to only provide a plain list of newline separated skills, do not provide any other information. There should be no other text. Only if the whole query doesn't contain enough information, return 'Cannot provide further skills summary'. "
    const requestSummary = () => {
      (async () => {
        const res = (await fetchGPT( summaryPrompt + description) || '...');
        setSummaryText(formatText(res.choices[0].text));
        console.log('summary: ', title, res.choices[0].text);
      })()
    };
    const requestSkills = () => {
      (async () => {
        const res = (await fetchGPT( skillsPrompt + description) || '...');
        setSkillText(formatText(res.choices[0].text));
        console.log('skill: ', title, res.choices[0].text);
      })()
    };

    ///SUMMARY
    // fast mode, preloading the summaries
    useEffect(()=>{
        //checking id data is already fetched, because hooks can run twice
        if (summaryFetchedRef.current || !preload) return;
        summaryFetchedRef.current = true;
        requestSummary();
    }, []);

    // eco mode (only request summary when needed)
    useEffect(() => {
        if (summarizing && !summaryFetchedRef.current ) {
            summaryFetchedRef.current = true; // check if already fetched
            requestSummary();
    }}, [summarizing]);
    
    ///SKILLS
    // fast mode, preloading the skills
    useEffect(()=>{
        //checking id data is already fetched, because hooks can run twice
        if (skillsFetchedRef.current || !preload) return;
        skillsFetchedRef.current = true;
        requestSkills();
    }, []);
    
    // eco mode (only request skills when needed)
    useEffect(() => {
        if (skillsDisplaying && !skillsFetchedRef.current ) {
            skillsFetchedRef.current = true; // check if already fetched
            requestSkills();
    }}, [skillsDisplaying]);


    const summary = (
        <div
          className={
            (summarizing ? 'z-20 ' : 'z-0  ') +
            'flex-col gap-10 p-5 items-center bg-custom-white rounded-l-lg w-fit '
          }
        >
          <div className="compare-header flex flex-row gap-5 justify-between ">
            <div
              className="compare-heading flex flex-row gap-1 justify-start text-brand cursor-pointer"
              onClick={() => setSummarizing(!summarizing)}
            >
              <CompressIcon />
              <h3 className="text-base font-bold">Summarize</h3>
            </div>
            <div className={summarizing ? 'flex cursor-pointer' : 'hidden'}>
              <IconButton onClick={() => setSummarizing(!summarizing)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className={summarizing ? 'flex flex-col gap-5 pt-5' : 'hidden'}>
            {/* body */}
            <p className="text-neutral-20 max-w-2xl text-left flex-grow whitespace-pre-line text-sm">

            {summaryText.length > 1 ?summaryText : <ClipLoader/>}
            </p>

          </div>
        </div>
      );
    const skills = (
        <div
          className={
            (skillsDisplaying ? 'z-20 ' : 'z-0  ') +
            'flex-col gap-10 p-5 items-center bg-custom-white rounded-l-lg w-fit '
          }
        >
          <div className="compare-header flex flex-row gap-5 justify-between ">
            <div
              className="compare-heading flex flex-row gap-1 justify-start text-brand cursor-pointer"
              onClick={() => setSkillsDisplaying(!skillsDisplaying)}
            >
              <EngineeringIcon />
              <h3 className="text-base font-bold">Skills</h3>
            </div>
            <div className={skillsDisplaying ? 'flex cursor-pointer' : 'hidden'}>
              <IconButton onClick={() => setSkillsDisplaying(!skillsDisplaying)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className={skillsDisplaying ? 'flex flex-col gap-5 pt-5' : 'hidden'}>
            {/* body */}
            <div className="text-neutral-20 max-w-2xl text-left flex-grow text-sm">

            {skillsText.length > 1 ? skillsText : <ClipLoader/>}
            </div>

          </div>
        </div>
      );

    return <>{summary}{skills}</>;
}

export default GPTFeatures;